import { Action } from "redux-ts";
import { takeEvery, put, select } from "redux-saga/effects";
import {
    artistSearchActions,
    errorActions,
    opsMetricsActions,
    userActions,
} from "../actions";
import { artistSearchProxy } from "../../service";
import {
    createSuccessOpsMetricsPayload,
    getArtistsFromResponse,
    paths,
} from "../../utils";
import { RootState } from "../reducers";
import {
    METRIC_KEYS,
    searchResults,
    artistSearchQueryPayload,
    ErrorPayload,
} from "../../models";
import { remoteSearchSelector } from "../selectors";
export const artistSearchSagas = [watchArtistSearch()];

function* searchForArtist(action: Action<artistSearchQueryPayload>) {
    const start = Date.now();
    const functionName = "searchForArtist";
    try {
        console.log("Searching for " + action.payload.query);
        yield put(artistSearchActions.artistSearchInProgress(true));

        // If the user is not privileged, dont do remote search
        const doRemoteSearch: boolean = yield select((state: RootState) =>
            remoteSearchSelector(state)
        );
        if (
            !doRemoteSearch &&
            action.payload.requestPath === paths.artistSelect
        ) {
            yield put(artistSearchActions.artistSearchCompleted([]));
            yield put(artistSearchActions.artistSearchInProgress(false));
            return;
        }

        // make the service call to get the artists
        const response: searchResults = yield artistSearchProxy(action.payload);
        // when we have a result, dispatch the completed task
        const artists = getArtistsFromResponse(response);

        console.log(
            `Got result for ${action.payload.query}  in ${
                Date.now() - start
            } ms`
        );

        yield put(artistSearchActions.artistSearchCompleted(artists));
        yield put(artistSearchActions.artistSearchInProgress(false));
        yield put(userActions.updateIsDeeplinkArtistSearchInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.artistAsin, `${action.payload.query}`],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        const payload: ErrorPayload = {
            requestPath: action.payload.requestPath,
            exception: ex,
            dataPoints: dataPoints,
            eventName: functionName,
        };

        yield put(artistSearchActions.artistSearchInProgress(false));
        yield put(errorActions.handleError(payload));
    }
}

function* watchArtistSearch() {
    yield takeEvery(artistSearchActions.artistSearch.type, searchForArtist);
}
