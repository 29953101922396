import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import {
    contentSearchActions,
    errorActions,
    opsMetricsActions,
} from "../actions";
import { trackAlbumSearchProxy } from "../../service";
import {
    createSuccessOpsMetricsPayload,
    getTrackAlbumsFromResponse,
} from "../../utils";
import {
    METRIC_KEYS,
    ErrorPayload,
    trackAlbumSearchQueryPayload,
    trackAlbumSearchResults,
} from "../../models";

export const contentSearchSagas = [watchTrackAlbumSearch()];

function* searchForTrackAlbum(action: Action<trackAlbumSearchQueryPayload>) {
    const start = Date.now();
    const functionName = "searchForTrackAlbum";
    try {
        console.log("Searching for " + action.payload.query);
        yield put(contentSearchActions.trackAlbumSearchInProgress(true));

        // make the service call to get the trackAlbum
        const response: trackAlbumSearchResults = yield trackAlbumSearchProxy(
            action.payload
        );
        // when we have a result, dispatch the completed task
        const trackAlbums = getTrackAlbumsFromResponse(response);

        console.log(
            `Got result for ${action.payload.query} in ${Date.now() - start} ms`
        );

        yield put(contentSearchActions.trackAlbumSearchCompleted(trackAlbums));
        yield put(contentSearchActions.trackAlbumSearchInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.artistAsin, `${action.payload.query}`],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        const payload: ErrorPayload = {
            requestPath: action.payload.requestPath,
            exception: ex,
            dataPoints: dataPoints,
            eventName: functionName,
        };

        yield put(contentSearchActions.trackAlbumSearchInProgress(false));
        yield put(errorActions.handleError(payload));
    }
}

function* watchTrackAlbumSearch() {
    yield takeEvery(
        contentSearchActions.trackAlbumSearch.type,
        searchForTrackAlbum
    );
}
