import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import {
    Table,
    Loading,
    IncrementalLoading,
    InlineError,
    ProfileHeadProps,
    ProfileHead,
} from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingState,
    RequiredReportingDispatchProps,
    TableRowProps,
    queryRequest,
    trackInfo,
    TimeRange,
    hydrateCatalogPayload,
    overviewRequestPayload,
    timeRangePayload,
    album,
    CatalogItemType,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    EntityType,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    albumTracksScreenSelector,
    reportingActions,
    catalogActions,
    userActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import {
    getLocalizedString,
    paths,
    getStartDate,
    HYDRATION_COUNT,
    generateSongRows,
    guessTimeZone,
    testIDSuffixes,
    hydrationHelpers,
    buildUIClickPayload,
    generateQuery,
} from "../../../utils";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "AlbumTracksScreen";
const metricPrefix = "albumTracksPage";
const pagePath = paths.albumTracks;

type ViewProps = {
    asin: string;
};

type StateProps = RequiredReportingProps & {
    albumTracks: trackInfo[];
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
};
type DispatchProps = RequiredReportingDispatchProps & {
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    loadedCount: number;
    album?: album;
};

class AlbumTracksScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports();
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
        }
        this.loadMore();

        const albumAsin = this.props.match.params.asin;
        const albumCatalogItem = this.props.catalog.get(albumAsin) as album;
        if (albumCatalogItem) {
            this.setState({ album: albumCatalogItem });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports();
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
            this.loadMore();
        }
    }

    render() {
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const tracksInfo = this.props.albumTracks.slice(
            0,
            this.state.loadedCount
        );
        const title = getLocalizedString(this.props.bundleMap, {
            bundleId: bundleIds.REPORTS_STRINGS,
            stringId: stringIds.Reports.streamDetailsTitle,
        });
        const topSongsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.songsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];

        const topSongs: TableRowProps[] = generateSongRows(
            tracksInfo,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            this.props.bundleMap,
            testIDPrefix + "_SongsRows",
            false,
            true,
            true
        );

        const csvPayload: createCsvFileRequestPayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: pagePath,
            csvExportType: "topSongs",
            csvButtonId: CsvButtonId.AlbumSeeAllTopSongs,
            contentName: this.state.album?.title || "",
            trackData: this.props.albumTracks,
        };

        let profileHeadProps: ProfileHeadProps = {
            imageSource: "",
            title: "",
            subtitle: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.albumTitle,
            }),
            id: testIDPrefix + testIDSuffixes.profileImage,
            showCsvButton:
                this.props.hasCsvExportCapabilities &&
                !!this.props.albumTracks.length,
            csvDownload: () => {
                this.props.sendClientMetrics(
                    buildUIClickPayload(
                        buttonIds.CsvExport.downloadSongs,
                        pageIds.albumTracks,
                        this.props.match.params.asin,
                        EntityType.Album
                    )
                );
                this.props.getCsvFile(csvPayload);
            },
            csvTooltipMessage: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.CSV_STRINGS,
                stringId: stringIds.Csv.TooltipMessage.TopSongs,
            }),
            csvButtonLoading:
                this.props.csvButtonLoadingStatus["AlbumSeeAll-TopSongs"],
        };

        if (this.state.album) {
            profileHeadProps = {
                ...profileHeadProps,
                imageSource:
                    this.state.album.images && this.state.album.images.artLarge
                        ? this.state.album.images.artLarge
                        : "",
                fallbackImage: ImageList.placeholder_album,
                title: this.state.album.title || "",
                imageDescription: this.state.album.title,
            };
        }

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    ...rootStyles.containerStyles.tableContainerStyle,
                }}
            >
                {/* {this.props.match.params?.image &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.match.params?.image }}
                    />
                } */}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {this.state.album && <ProfileHead {...profileHeadProps} />}
                <Row>
                    <Col>
                        <Table
                            loading={this.state.refreshing}
                            loadMore={this.loadMore}
                            rows={topSongs}
                            labels={topSongsHeader}
                            title={title}
                            scrolling={true}
                            footer={
                                this.props.albumTracks.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                            id={testIDPrefix + "_SongsTable"}
                            showIndex={true}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    private loadMore = () => {
        if (!this.props.signedIn) {
            return;
        }

        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            this.props.albumTracks,
            this.props.hydrateAsins,
            CatalogItemType.Tracks,
            this.props.userLocale
        );

        this.setState({
            loadedCount: nextCount,
        });
    };

    private getReports = () => {
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }

        this.setState({
            loadedCount: HYDRATION_COUNT,
        });

        const requestId = `seeAll_albumTracks_${this.props.selectedRange.toString()}`;

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
            topTracksQueryV2: {
                ...generateQuery(timeRangePayload, guessTimeZone(), 300),
                albumAsin: asin,
            },
        };

        const payload: overviewRequestPayload = {
            query: query,
            requestPath: paths.albumTracks,
            requestId: requestId,
            isRefresh: true,
            teamId: this.props.teamId,
        };

        this.props.getReport(payload);
    };
}

function mapStateToProps(state: RootState) {
    return albumTracksScreenSelector(state, paths.albumTracks);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReport: (payload: overviewRequestPayload) =>
            dispatch(reportingActions.getReports(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AlbumTracksScreen)
);
