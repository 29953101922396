import { Bundle, BundleMap, MessageBlob } from "../models";
import countryTranslator from "i18n-iso-countries";
import { stringIds, terms, bundleIds } from "../assets";
import {
    TeamRole,
    JobTitle,
    ArtistProfileIssueType,
    ContentType,
} from "../models";
import { webLocale, locale } from "./localeType";
import { EmailValidator } from "commons-validator-js";
import store from "../store/store";
import { localizationActions } from "../store/actions";
import { FailedToResolveStringIdPayload } from "../models";
import { pseudoLoc, reactAppEnv } from "./../service/common";
import { customizedMessageBundleManager } from "./pantherConfiguration";
import { getPseudoLocString } from "./pseudoLocHelper";

const validator = new EmailValidator();

export const PANTHER_BUNDLE_PREFIX =
    "com.amazon.amazon-music.amazon-music-for-artists";

export const getLocalizedTerms = () => {
    const userLocale = webLocale[0];
    switch (userLocale) {
        case locale.deDE:
            return terms.deDE;
        case locale.enUS:
            return terms.enUS;
        case locale.esES:
            return terms.esES;
        case locale.frFR:
            return terms.frFR;
        case locale.itIT:
            return terms.itIT;
        case locale.jaJP:
            return terms.jaJP;
        case locale.ptBR:
            return terms.ptBR;
        default:
            return terms.enUS;
    }
};

export const translateTeamRoleToString = (
    role: TeamRole,
    bundleMap: BundleMap
) => {
    switch (role) {
        case TeamRole.Owner:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.teamRoleOwner,
            });
        case TeamRole.Admin:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.teamRoleAdmin,
            });
        case TeamRole.Viewer:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.teamRoleViewer,
            });
        case TeamRole.Custom:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.teamRoleCustom,
            });
    }
};

export const translateStringToTeamRole = (
    role: string,
    bundleMap: BundleMap
) => {
    switch (role) {
        case getLocalizedString(bundleMap, {
            bundleId: bundleIds.ROLES_STRINGS,
            stringId: stringIds.Roles.teamRoleOwner,
        }):
            return TeamRole.Owner;
        case getLocalizedString(bundleMap, {
            bundleId: bundleIds.ROLES_STRINGS,
            stringId: stringIds.Roles.teamRoleAdmin,
        }):
            return TeamRole.Admin;
        case getLocalizedString(bundleMap, {
            bundleId: bundleIds.ROLES_STRINGS,
            stringId: stringIds.Roles.teamRoleViewer,
        }):
            return TeamRole.Viewer;
        default:
            return TeamRole.Viewer;
    }
};

export const translateJobTitleToString: (
    role: JobTitle,
    bundleMap: BundleMap
) => string = (role: JobTitle, bundleMap: BundleMap) => {
    switch (role) {
        case JobTitle.Artist:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.jobTitleArtist,
            });
        case JobTitle.Manager:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.jobTitleManager,
            });
        case JobTitle.LabelRep:
            return getLocalizedString(bundleMap, {
                bundleId: bundleIds.ROLES_STRINGS,
                stringId: stringIds.Roles.jobTitleLabelRep,
            });
    }
};

export const translateCountryCodeToCountryName = (countryCode: string) => {
    const languageCode = webLocale[0].split("_")[0];
    //fall back to English if the languageCode is not supported by countryTranslator
    return countryTranslator.getName(countryCode, languageCode)
        ? countryTranslator.getName(countryCode, languageCode)
        : countryTranslator.getName(countryCode, "en");
};

export const canTranslateCountryCodeToCountryName = (countryCode: string) => {
    return countryTranslator.isValid(countryCode);
};

export const translateOutageStatusForMessage = (
    outageStatusMessage: MessageBlob
) => {
    const locale = webLocale[0];
    return outageStatusMessage[locale];
};

export const translateIssueToReportToString: (
    issueType: ArtistProfileIssueType
) => string = (issueType: ArtistProfileIssueType) => {
    switch (issueType) {
        case ArtistProfileIssueType.IncorrectContent:
            return stringIds.ArtistDisambiguation
                .selectIssueIncorrectArtistContent;
        case ArtistProfileIssueType.ContentOnDifferentArtist:
            return stringIds.ArtistDisambiguation
                .contentOnDifferentArtistProfile;
    }
};

export const translateContentTypeToString: (
    contentType: string
) => string | undefined = (contentType: string) => {
    switch (contentType.toUpperCase()) {
        case ContentType.Track:
            return stringIds.ArtistDisambiguation.contentTypeTrack;
        case ContentType.Album:
            return stringIds.ArtistDisambiguation.contentTypeAlbum;
        default:
            return "";
    }
};

const is7bitAscii = (str: string): boolean => {
    // inclusive
    const min7bitValue = 33;
    const max7bitValue = 126;

    for (let i = 0; i < str.length; ++i) {
        const intVal: number = str.charCodeAt(i);
        if (intVal < min7bitValue || intVal > max7bitValue) {
            return false;
        }
    }

    return true;
};

export const emailIsValid = (email: string) => {
    const trimmedEmail: string =  email.trim();
    return (
        validator.isValid(trimmedEmail) &&
        !hasWhitespace(trimmedEmail) &&
        trimmedEmail
            .split("@")
            .map(is7bitAscii)
            .reduce((prev: boolean, curr: boolean) => prev && curr, true)
    );
};

export const hasWhitespace = (string: string) => {
    return string.match(/(\s)/i);
};

export interface StringFormatArgs {
    [key: string]: string | number | CurrencyFormat | Date;
}

export type CurrencyFormat = {
    amount: number;
    currency: {
        currencyCode: string;
    };
};

export type localizedID = {
    bundleId: string;
    stringId: string;
};

export const getLocalizedString = (
    bundleMap: BundleMap,
    localizedId: localizedID,
    formatArgs?: StringFormatArgs
): string => {
    const bundle: Bundle = bundleMap.get(localizedId.bundleId)!;
    try {
        let localizedString = bundle.getMessage(localizedId.stringId);
        if (formatArgs) {
            localizedString = bundle.formatMessage(
                localizedId.stringId,
                formatArgs
            );
        }
        if (pseudoLoc && webLocale[0] === locale.enUS) {
            return getPseudoLocString(localizedString);
        }
        return localizedString;
    } catch (e) {
        if (reactAppEnv === "local") {
            throw e;
        }
        const failedToResolvePayload: FailedToResolveStringIdPayload = {
            bundleId: localizedId.bundleId,
            stringId: localizedId.stringId,
        };

        store.dispatch(
            localizationActions.failedToResolveStringId(failedToResolvePayload)
        );
    }
    return "";
};

export async function getLocalizedStringFromLocale(
    locale: string,
    localizedId: localizedID,
    formatArgs?: StringFormatArgs
) {
    const mbm = customizedMessageBundleManager(locale);
    const bundle: Bundle = await mbm.getBundle(
        `${PANTHER_BUNDLE_PREFIX}.${localizedId.bundleId}`
    );
    try {
        if (!formatArgs) {
            return bundle.getMessage(localizedId.stringId);
        }
        return bundle.formatMessage(localizedId.stringId, formatArgs);
    } catch (e) {
        if (reactAppEnv === "local") {
            throw e;
        }
        const failedToResolvePayload: FailedToResolveStringIdPayload = {
            bundleId: localizedId.bundleId,
            stringId: localizedId.stringId,
        };

        store.dispatch(
            localizationActions.failedToResolveStringId(failedToResolvePayload)
        );
    }
    return "";
}
