import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AnyAction } from "redux";
import { licenses, stringIds, bundleIds } from "../../../assets";
import { BundleMap } from "../../../models";
import { RootState } from "../../../store";
import { getLocalizedString, paths } from "../../../utils";
import { CommonHeader, styledTitle } from "../../components";
import * as rootStyles from "../../styles";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

export const openSourceAttributionIdPrefix = "OpenSourceAttribution";
const testIDPrefix = "OpenSourceAttributionScreen";
const metricPrefix = "OpenSourceAttributionPage";

type ViewProps = {};

type StateProps = {
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

export class OpenSourceAttributionScreen extends React.Component<Props> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidUpdate(prevProps: Props) {
    }

    render() {
        return (
            <Container
                id={openSourceAttributionIdPrefix + "-Container"}
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SETTINGS_STRINGS,
                        stringId:
                            stringIds.Settings.openSourceAttribution,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideArtistImgDropdown={true}
                    hideDatePicker={true}
                />
                <Row
                    id={openSourceAttributionIdPrefix + "-Content"}
                    style={rowStyle}
                >
                    <Col>
                        <span
                            style={{
                                ...rootStyles.textStyles.primary,
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                            }}
                        >
                            {licenses}
                        </span>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const rowStyle: React.CSSProperties = {
    marginBottom: rootStyles.spacers.medium,
};

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OpenSourceAttributionScreen)
);
