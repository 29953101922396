import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {} from "@amzn/arb-tools";
import * as rootStyles from "../../styles";
import { BundleMap } from "../../../models";
import { RootState, } from "../../../store";
import { stringIds, bundleIds } from "../../../assets";
import { getLocalizedString, getLocalizedTerms, paths } from "../../../utils";
import { CommonHeader } from "../../components";
import "../../styles/termsAndConditions.css";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

export const termsAndConditionsIdPrefix = "TermsAndConditions";
const testIDPrefix = "TermsAndConditionsScreen";
const metricPrefix = "TermsAndConditionsPage";

type ViewProps = {};

type StateProps = {
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

export class TermsAndConditionsScreen extends React.Component<Props> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps: Props) {
    }

    render() {
        return (
            <Container
                id={termsAndConditionsIdPrefix + "-Container"}
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SETTINGS_STRINGS,
                        stringId: stringIds.Settings.tocLabel,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideArtistImgDropdown={true}
                    hideDatePicker={true}
                />
                <Row
                    id={termsAndConditionsIdPrefix + "-Content"}
                    style={rowStyle}
                >
                    <Col>{getLocalizedTerms()}</Col>
                </Row>
            </Container>
        );
    }
}

const rowStyle: React.CSSProperties = {
    marginBottom: rootStyles.spacers.medium,
};

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {

}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsScreen)
);
