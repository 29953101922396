import { all } from "redux-saga/effects";
import {
    artistIssueSagas,
    artistImageSagas,
    artistSearchSagas,
    reportingSagas,
    artistClaimSagas,
    catalogSagas,
    csvSagas,
    authSagas,
    oAuthSagas,
    teamManagementSagas,
    errorSagas,
    outageStatusSagas,
    contentSearchSagas,
    featureAccessSagas,
    pitchSagas,
    merchSearchSagas,
    hydrateMerchSagas,
    searchCollectionsSagas,
    curateMerchSagas,
    clientMetricsSagas,
    localizationSagas,
    batchMetricSaga,
    opsMetricSagas,
    timedEmitterSagas,
    deeplinkSagas,
} from ".";

// Combine all of the sagas here
export default function* rootSaga() {
    yield all([
        ...artistIssueSagas,
        ...artistImageSagas,
        ...artistSearchSagas,
        ...reportingSagas,
        ...csvSagas,
        ...artistClaimSagas,
        ...catalogSagas,
        ...authSagas,
        ...oAuthSagas,
        ...teamManagementSagas,
        ...errorSagas,
        ...outageStatusSagas,
        ...contentSearchSagas,
        ...featureAccessSagas,
        ...pitchSagas,
        ...merchSearchSagas,
        ...searchCollectionsSagas,
        ...hydrateMerchSagas,
        ...curateMerchSagas,
        ...clientMetricsSagas,
        ...localizationSagas,
        ...batchMetricSaga,
        ...opsMetricSagas,
        ...timedEmitterSagas,
        ...deeplinkSagas,
    ]);
}
