import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import {
    artistClaimActions,
    errorActions,
    opsMetricsActions,
} from "../actions";
import { makeClaim, checkPendingClaimStatus } from "../../service";
import {
    claimRequestPayload,
    ErrorPayload,
    checkPendingClaimPayload,
    CheckPendingClaimResponse,
    METRIC_KEYS,
} from "../../models";
import { createSuccessOpsMetricsPayload } from "../../utils";

export const artistClaimSagas = [watchClaimArtist(), watchCheckPendingClaim()];

function* claimArtist(action: Action<claimRequestPayload>) {
    const start = Date.now();
    const functionName = "claimArtist";
    try {
        yield put(artistClaimActions.claimArtistInProgress(true));
        yield put(artistClaimActions.claimArtistCompleted(false));

        yield makeClaim(action.payload);

        yield put(artistClaimActions.claimArtistCompleted(true));
        yield put(artistClaimActions.claimArtistInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.artistAsin, `${action.payload.artistAsin}`],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        const payload: ErrorPayload = {
            requestPath: action.payload.requestPath,
            exception: ex,
            dataPoints: dataPoints,
            eventName: functionName,
        };

        yield put(errorActions.handleError(payload));
        yield put(errorActions.displayError(payload));
        yield put(artistClaimActions.claimArtistCompleted(false));
        yield put(artistClaimActions.claimArtistInProgress(false));
    }
}

function* checkPendingClaim(action: Action<checkPendingClaimPayload>) {
    const start = Date.now();
    const functionName = "checkPendingClaim";
    try {
        yield put(artistClaimActions.checkPendingClaimCompleted(undefined));
        yield put(artistClaimActions.checkPendingClaimInProgress(true));

        const result: CheckPendingClaimResponse = yield checkPendingClaimStatus(
            action.payload
        );

        yield put(
            artistClaimActions.checkPendingClaimCompleted(result.isPending)
        );
        yield put(artistClaimActions.checkPendingClaimInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.artistAsin, `${action.payload.artistAsin}`],
                ]),
            })
        );
        yield put(artistClaimActions.checkPendingClaimCompleted(false));
        yield put(artistClaimActions.checkPendingClaimInProgress(false));
    }
}

function* watchClaimArtist() {
    yield takeEvery(artistClaimActions.claimArtist.type, claimArtist);
}

function* watchCheckPendingClaim() {
    yield takeEvery(
        artistClaimActions.checkPendingClaim.type,
        checkPendingClaim
    );
}
