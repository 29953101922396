import * as React from "react";
import { connect } from "react-redux";
import { Container, Col, Row } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch, AnyAction } from "redux";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import {
    RequiredReportingProps,
    RequiredReportingDispatchProps,
    RequiredReportingState,
    fanTiersInfo,
    TimeRange,
    TableRowProps,
    countryInfo,
    cityInfo,
    trackListenersInfo,
    reportingOverviewRequestPayload,
    recentlyAddedToPlaylistPayload,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    EntityType,
    FanTiersType,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    LineGraphProps,
    LineGraph,
    Loading,
    SmallGlassButton,
    VerticalSpacedTable,
    InlineError,
    CommonHeader,
} from "../../components";
import {
    getLocalizedString,
    timeRangeToTickCount,
    paths,
    formatDatum,
    formatTooltipDatum,
    getReportsHelper,
    parseFanTierDataPoints,
    parseSuperFanTierDataPoints,
    generateCountryRows,
    SUMMARY_TABLE_ITEM_COUNT,
    parseTrackDataPoints,
    guessTimeZone,
    formatYAxisDiffView,
    getSuperFansCount,
    buildUIClickPayload,
    formatNumber,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    fansScreenSelector,
    RootState,
    reportingActions,
    userActions,
    catalogActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";

const testIDPrefix = "FansScreen";
const metricPrefix = "fansPage";

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    fanTiersInfo: fanTiersInfo;
    topCountries: countryInfo[];
    trackListeners?: trackListenersInfo;
    topCities: cityInfo[];
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    getReports: (payload: reportingOverviewRequestPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getRecentlyAddedToPlaylist: (
        payload: recentlyAddedToPlaylistPayload
    ) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {};

class FansScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports();
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.state.loadedReports &&
            this.props.catalogBuildCompleted &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            if (
                this.props.error &&
                this.props.error.requestPath === paths.fans
            ) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        // Listeners props
        const listenersCount = this.props.trackListeners
            ? this.props.trackListeners.totalCount
            : 0;
        const totalListenersWOW = this.props.trackListeners
            ? this.props.trackListeners.delta
            : "N/A";
        const parsedTrackListeners =
            this.props.trackListeners &&
            parseTrackDataPoints(
                this.props.trackListeners,
                this.props.selectedRange === TimeRange.AllTime
            );
        const listenersLineChartProps: LineGraphProps | undefined =
            parsedTrackListeners && {
                data: [parsedTrackListeners],
                title: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.totalListenersTitle,
                    },
                    { "0": formatNumber(listenersCount) }
                ),
                subtitle: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.weekOverWeek,
                    },
                    { "0": formatNumber(totalListenersWOW) }
                ),
                labels:
                    this.props.selectedRange === TimeRange.AllTime
                        ? [
                              getLocalizedString(this.props.bundleMap, {
                                  bundleId: bundleIds.REPORTS_STRINGS,
                                  stringId: stringIds.Reports.allTimeSubtitle,
                              }),
                          ]
                        : undefined,
                xTickCount: timeRangeToTickCount(this.props.selectedRange),
                tickFormat: (datum: any) =>
                    formatDatum(datum, this.props.selectedRange, userTimeZone),
                tooltipFormat: (datum: any) =>
                    formatTooltipDatum(
                        datum,
                        this.props.selectedRange,
                        userTimeZone
                    ),
                id: testIDPrefix + "_ListenerCountLineChart",
                bundleMap: this.props.bundleMap,
            };

        // Fan props
        const latestFansCount =
            (this.props.fanTiersInfo && this.props.fanTiersInfo.latestCount) ||
            0;
        // const totalFansWOW = this.props.fanTiersInfo && this.props.fanTiersInfo.delta || "N/A";
        const userTimeZone = guessTimeZone();
        const totalFansProps: LineGraphProps | undefined = this.props
            .fanTiersInfo && {
            data: [
                [],
                parseFanTierDataPoints(
                    this.props.fanTiersInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title:
                this.props.selectedRange === TimeRange.Today
                    ? getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.REPORTS_STRINGS,
                          stringId: stringIds.Reports.latestFans24HrsTitle,
                      })
                    : getLocalizedString(
                          this.props.bundleMap,
                          {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.latestFansTitle,
                          },
                          { "0": formatNumber(latestFansCount) }
                      ),
            // subtitle: getLocalizedString(stringIds.Reports.weekOverWeek, formatNumber(totalFansWOW)),
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            useDynamicMin: true,
            toolTipStrokeColor: rootStyles.colors.aux3,
            emptyMessage:
                this.props.selectedRange === TimeRange.Today
                    ? getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.GENERIC_STRINGS,
                          stringId:
                              stringIds.Generic.fanTiersFor24hrsUnavailable,
                      })
                    : undefined,
            footNoteText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.FANS_STRINGS,
                stringId: stringIds.Fans.fans_subtitle,
            }),
            footNoteClickableText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.FANS_STRINGS,
                stringId: stringIds.Fans.more_information_here,
            }),
            footNoteUrl:
                "https://artists.amazonmusic.com/frequently-asked-questions#who-are-fans",
            dependentAxisTickFormat: (datum: any) => formatYAxisDiffView(datum),
            id: testIDPrefix + "_FanTiersLineGraph",
            bundleMap: this.props.bundleMap,
        };

        const totalSuperFansProps: LineGraphProps | undefined = this.props
            .fanTiersInfo && {
            data: [
                [],
                [],
                parseSuperFanTierDataPoints(
                    this.props.fanTiersInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title:
                this.props.selectedRange === TimeRange.Today
                    ? getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.REPORTS_STRINGS,
                          stringId: stringIds.Reports.latestSuperFans24HrsTitle,
                      })
                    : getLocalizedString(
                          this.props.bundleMap,
                          {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.latestSuperFansTitle,
                          },
                          {
                              "0": formatNumber(
                                  getSuperFansCount(this.props.fanTiersInfo)
                              ),
                          }
                      ),
            // subtitle: getLocalizedString(stringIds.Reports.weekOverWeek, formatNumber(totalFansWOW)),
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            useDynamicMin: true,
            footNoteText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.FANS_STRINGS,
                stringId: stringIds.Fans.superfans_subtitle,
            }),
            footNoteClickableText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.FANS_STRINGS,
                stringId: stringIds.Fans.more_information_here,
            }),
            footNoteUrl:
                "https://artists.amazonmusic.com/frequently-asked-questions#who-are-superfans",
            emptyMessage:
                this.props.selectedRange === TimeRange.Today
                    ? getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.GENERIC_STRINGS,
                          stringId:
                              stringIds.Generic
                                  .superFanTiersFor24hrsUnavailable,
                      })
                    : undefined,
            toolTipStrokeColor: rootStyles.colors.aux1,
            dependentAxisTickFormat: (datum: any) => formatYAxisDiffView(datum),
            bundleMap: this.props.bundleMap,
            id: testIDPrefix + "_SuperFanTiersLineGraph",
        };

        // Top countries props
        const topCountriesHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.countriesTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.listenersLabel,
            }),
        ];
        const topCountries: TableRowProps[] = generateCountryRows(
            this.props.topCountries,
            testIDPrefix + "_TopCountriesRows"
        );

        const topCountriesRows = topCountries.slice(
            0,
            SUMMARY_TABLE_ITEM_COUNT
        );

        const fansCsvPayload: createCsvFileRequestPayload = {
            csvExportType: "fans",
            csvButtonId: CsvButtonId.FansTrendlines,
            fanData: this.props.fanTiersInfo,
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: paths.fans,
        };

        const fanDataEligibleForCsv = !!this.props.fanTiersInfo?.fansInfoList
            ?.find((x) => x.type === FanTiersType.Fan)
            ?.dataPointList.some((d) => d.value !== 0);

        const superFanDataEligibleForCsv =
            !!this.props.fanTiersInfo?.fansInfoList
                ?.find((x) => x.type === FanTiersType.SuperFan)
                ?.dataPointList.some((d) => d.value !== 0);
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.fansTitle,
                    })}
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.fansSubtitle,
                    })}
                    id={`${testIDPrefix}_Header`}
                    showCsvButton={
                        this.props.hasCsvExportCapabilities &&
                        (fanDataEligibleForCsv || superFanDataEligibleForCsv)
                    }
                    csvDownload={() => {
                        this.props.sendClientMetrics(
                            buildUIClickPayload(
                                buttonIds.CsvExport.downloadTrendlines,
                                pageIds.fans,
                                this.props.selectedArtist,
                                EntityType.ARTIST
                            )
                        );
                        this.props.getCsvFile(fansCsvPayload);
                    }}
                    csvTooltipMessage={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.CSV_STRINGS,
                            stringId: stringIds.Csv.TooltipMessage.Fans,
                        }
                    )}
                    csvButtonLoading={
                        this.props.csvButtonLoadingStatus["Fans-Trendlines"]
                    }
                />
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        {
                            // Total fans
                            totalFansProps && (
                                <LineGraph
                                    key="totalFans"
                                    {...totalFansProps}
                                />
                            )
                        }
                    </Col>
                    <Col xs={12} md={6}>
                        {
                            // Total fans
                            totalSuperFansProps && (
                                <LineGraph
                                    key="totalFans"
                                    {...totalSuperFansProps}
                                />
                            )
                        }
                    </Col>
                </Row>
                {
                    <Row>
                        <Col>
                            <VerticalSpacedTable
                                rows={topCountriesRows}
                                labels={topCountriesHeader}
                                showIndex={true}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports.topCountriesTitle,
                                    }
                                )}
                                id={testIDPrefix + "_TopCountriesTable"}
                                footer={
                                    topCountries.length >
                                    SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllCountries}
                                            id={
                                                testIDPrefix +
                                                "_seeAllCountries"
                                            }
                                        />
                                    ) : null
                                }
                                emptyMessage={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                        stringId: stringIds.EmptyState.message,
                                    }
                                )}
                            />
                        </Col>
                    </Row>
                }
            </Container>
        );
    }

    private seeAllCountries = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllCountries,
                pageIds.fans,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );
        this.props.history.push(paths.countries);
    };

    private getReports = () => {
        getReportsHelper(
            this.props.getReports,
            this.props.getRecentlyAddedToPlaylist,
            this.props.selectedRange,
            paths.fans,
            this.props.selectedArtist,
            this.props.teamId,
            this.props.startDate,
            this.props.endDate
        );
    };
}

function mapStateToProps(state: RootState) {
    return fansScreenSelector(state, paths.fans);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReports: (payload: reportingOverviewRequestPayload) =>
            dispatch(reportingActions.getFanTiersReports(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getRecentlyAddedToPlaylist: (payload: recentlyAddedToPlaylistPayload) =>
            dispatch(catalogActions.getRecentlyAddedToPlaylists(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FansScreen)
);
