import _ from "lodash";
import { CsvHelper, FilenameInfo, TopContent } from "./../../../export";
import {
    catalog,
    contentInfo,
    CatalogItemType,
    BundleMap,
} from "../../../models/";
import { CatalogState } from "./../../reducers";
import {
    getLocalizedString,
    iterativeHydrationCall,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { CsvType } from "../../../export/csv/csvTypes";

const ERROR_PREFIX = new Map<CsvType, string>([
    ["topSongs", "TopTracks"],
    ["topSongsOnAlbum", "TopTracksOnAlbum"],
    ["topAlbums", "TopAlbums"],
    ["featuredInStations", "TopStations"],
]);

export async function createTopContentCsvFile({
    csvType,
    catalogItemType,
    startDate,
    endDate,
    contentInfoList,
    teamId,
    catalogState,
    locale,
    artistName,
    contentName,
    requestPath,
    bundleMap
}: {
    csvType: CsvType;
    catalogItemType: CatalogItemType;
    startDate: Date;
    endDate: Date;
    contentInfoList: contentInfo[];
    teamId: string;
    catalogState: CatalogState;
    locale: string;
    artistName: string;
    contentName?: string;
    requestPath: string;
    bundleMap: BundleMap;
}) {
    const start = Date.now();
    const asins = contentInfoList.map((content) => content.asin);

    const hydrationPayload = {
        catalogState: catalogState,
        hydrationType: catalogItemType,
        asins: asins,
        teamId: teamId,
        locale: locale,
    };
    const compositeCatalog: catalog = await iterativeHydrationCall(
        hydrationPayload
    );

    let csvRowData: TopContent[] = [];
    contentInfoList.forEach((content) => {
        const title: string | undefined = compositeCatalog.get(
            content.asin
        )?.title;
        csvRowData.push([
            title ||
                getLocalizedString(bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.missingCatalogDataTitle,
                }),
            content.streams,
        ]);
    });

    //define file name info
    const filenameInfo: FilenameInfo = {
        artistName: artistName,
        contentName: contentName,
        startDate: startDate as Date,
        endDate: endDate as Date,
    };

    //download csv
    CsvHelper.downloadCSV(csvType, csvRowData, filenameInfo, bundleMap);
}
