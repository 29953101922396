import { Action } from "redux-ts";
import * as services from "../../service";
import {
    albumRelease,
    CatalogItemType,
    createPitchPayload,
    createPitchResponse,
    getPitchFormDataPayload,
    getPitchFormDataResponse,
    listAlbumsToPitchPayload,
    listAlbumsToPitchResponse,
    listRelatedArtistsPayload,
    listRelatedArtistsResponse,
    listTracksToPitchPayload,
    listTracksToPitchResponse,
    METRIC_KEYS,
    PitchStatus,
    QueryType,
    trackInfoForPitch,
} from "../../models";
import {
    catalogActions,
    errorActions,
    opsMetricsActions,
    pitchActions,
} from "../actions";
import { put, takeEvery } from "redux-saga/effects";
import { createSuccessOpsMetricsPayload, hydrationHelpers } from "../../utils";
import { CommonTestData } from "../../models/commonTestData";
import _ from "lodash";

export const pitchSagas = [
    watchCreatePitch(),
    watchGetPitchFormData(),
    watchGetAlbumsToPitch(),
    watchGetTracksToPitch(),
    watchGetRelatedArtists(),
];

function* createPitch(action: Action<createPitchPayload>) {
    const start = Date.now();
    const functionName = "createPitch";
    try {
        yield put(pitchActions.createPitchInProgress(true));
        yield put(pitchActions.createPitchCompleted(undefined));

        const result: createPitchResponse = yield services.createPitch(
            action.payload.request,
            action.payload.teamId
        );
    
        yield put(
            pitchActions.createPitchCompleted(result.pitchId !== undefined)
        );
        yield put(pitchActions.createPitchInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(pitchActions.createPitchInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getPitchFormData(action: Action<getPitchFormDataPayload>) {
    const start = Date.now();
    const functionName = "getPitchFormData";
    try {
        yield put(pitchActions.getPitchFormDataInProgress(true));

        const result: getPitchFormDataResponse =
            yield services.getPitchFormData(
                action.payload.pitchType,
                action.payload.locale
            );
    
        yield put(pitchActions.getPitchFormDataInProgress(false));
        yield put(pitchActions.getPitchFormDataCompleted(result));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(pitchActions.getPitchFormDataInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getAlbumsToPitch(action: Action<listAlbumsToPitchPayload>) {
    const start = Date.now();
    const functionName = "getAlbumsToPitch";
    try {
        yield put(pitchActions.listAlbumsToPitchInProgress(true));

        const listAlbumsToPitchReponse: listAlbumsToPitchResponse =
            yield services.listAlbumsToPitch(
                action.payload.artistAsin,
                action.payload.teamId,
                action.payload.paginationToken
            );

        const albumList: albumRelease[] = listAlbumsToPitchReponse.releases;
        const albumTitlesetAsins: string[] = albumList.map(
            (album) => album.titlesetAsin
        );

        const albumGlobalAsins: string[] = _.filter(
            _.map(albumList, (album) => {
                return album.globalAsin || "";
            })
        );

        const albumTitlesetAsinToGlobalAsinMap: Map<string, string> =
            hydrationHelpers.getTitlesetAsinToGlobalAsinMap(
                albumList.map((album) => {
                    return {
                        asin: album.titlesetAsin,
                        globalAsin: album.globalAsin,
                    };
                })
            );
        const albumReleasesWithPitchableTestAsins: albumRelease[] =
            CommonTestData.testArtistAsins.includes(action.payload.artistAsin)
                ? listAlbumsToPitchReponse.releases.map((release) => {
                      return {
                          ...release,
                          status: PitchStatus.Pitchable,
                      };
                  })
                : listAlbumsToPitchReponse.releases;

        const filteredAlbumReleaseList: albumRelease[] =
            albumReleasesWithPitchableTestAsins.filter(
                (albumRelease) =>
                    albumRelease.titlesetAsin && albumRelease.globalAsin
            );

        yield put(
            catalogActions.hydrateAsins({
                asins: albumTitlesetAsins,
                titleSetAsinToGlobalAsinMap: albumTitlesetAsinToGlobalAsinMap,
                type: CatalogItemType.Albums,
                locale: action.payload.locale,
            })
        );

        yield put(
            catalogActions.hydrateAsins({
                asins: albumGlobalAsins,
                titleSetAsinToGlobalAsinMap: albumTitlesetAsinToGlobalAsinMap,
                type: CatalogItemType.Albums,
                locale: action.payload.locale,
            })
        );

        

        yield put(pitchActions.listAlbumsToPitchInProgress(false));
        yield put(
            pitchActions.listAlbumsToPitchComplete({
                paginationToken: listAlbumsToPitchReponse.paginationToken,
                releases: filteredAlbumReleaseList,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(pitchActions.listAlbumsToPitchInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getTracksToPitch(action: Action<listTracksToPitchPayload>) {
    const start = Date.now();
    const functionName = "getTracksToPitch";
    try {
        yield put(pitchActions.listTracksToPitchInProgress(true));

        const listTracksToPitcResponse: listTracksToPitchResponse =
            yield services.listTracksToPitch(
                action.payload.artistAsin,
                action.payload.albumTitlesetAsin,
                action.payload.teamId,
                action.payload.albumGlobalAsin
            );

        const trackList: trackInfoForPitch[] = listTracksToPitcResponse.tracks;
        const trackAsins: string[] = trackList.map(
            (track) => track.titlesetAsin
        );

        const trackTitlesetAsinToGlobalAsinMap: Map<string, string> =
            hydrationHelpers.getTitlesetAsinToGlobalAsinMap(
                trackList.map((track) => {
                    return {
                        asin: track.titlesetAsin,
                        globalAsin: track.globalAsin,
                    };
                })
            );
        const trackListWithPitchableTestAsins: trackInfoForPitch[] =
            CommonTestData.testArtistAsins.includes(action.payload.artistAsin)
                ? listTracksToPitcResponse.tracks.map((track) => {
                      return {
                          ...track,
                          status: PitchStatus.Pitchable,
                      };
                  })
                : listTracksToPitcResponse.tracks;

        const filteredTrackList: trackInfoForPitch[] =
            trackListWithPitchableTestAsins.filter(
                (track) => track.titlesetAsin && track.globalAsin
            );

        yield put(
            catalogActions.hydrateAsins({
                asins: trackAsins,
                titleSetAsinToGlobalAsinMap: trackTitlesetAsinToGlobalAsinMap,
                type: CatalogItemType.Tracks,
                locale: action.payload.locale,
            })
        );

        yield put(pitchActions.listTracksToPitchInProgress(false));
        yield put(
            pitchActions.listTracksToPitchComplete({
                tracks: filteredTrackList,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(pitchActions.listTracksToPitchInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getRelatedArtists(action: Action<listRelatedArtistsPayload>) {
    const start = Date.now();
    const functionName = "getRelatedArtists";
    try {
        yield put(pitchActions.listRelatedArtistsInProgress(true));

        const listRelatedArtistsResponse: listRelatedArtistsResponse =
            yield services.listRelatedArtists(
                action.payload.artistAsin,
                action.payload.teamId
            );

        yield put(
            catalogActions.hydrateAsins({
                asins: listRelatedArtistsResponse.artists,
                type: CatalogItemType.Artists,
            })
        );

        yield put(pitchActions.listRelatedArtistsInProgress(false));
        yield put(
            pitchActions.listRelatedArtistsComplete({
                artists: listRelatedArtistsResponse.artists,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(pitchActions.listRelatedArtistsInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* watchCreatePitch() {
    yield takeEvery(pitchActions.createPitch.type, createPitch);
}

function* watchGetPitchFormData() {
    yield takeEvery(pitchActions.getPitchFormData.type, getPitchFormData);
}

function* watchGetAlbumsToPitch() {
    yield takeEvery(pitchActions.listAlbumsToPitch.type, getAlbumsToPitch);
}

function* watchGetTracksToPitch() {
    yield takeEvery(pitchActions.listTracksToPitch.type, getTracksToPitch);
}

function* watchGetRelatedArtists() {
    yield takeEvery(pitchActions.listRelatedArtists.type, getRelatedArtists);
}
