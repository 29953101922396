import _ from "lodash";
import {
    FanTiersType,
    fanTiersInfo,
    BundleMap,
} from "../../../models/";
import { CsvHelper, FilenameInfo, Trendline } from "./../../../export/";

export function createFansCsvFile({
    startDate,
    endDate,
    fanData,
    artistName,
    requestPath,
    bundleMap,
}: {
    startDate: Date;
    endDate: Date;
    fanData: fanTiersInfo;
    artistName: string;
    requestPath: string;
    bundleMap: BundleMap;
}) {
    const start = Date.now();
    const fansInfoList = fanData.fansInfoList;
    const fans = fansInfoList.find((x) => x.type === FanTiersType.Fan);
    const superFans = fansInfoList.find(
        (x) => x.type === FanTiersType.SuperFan
    );
    const fansDatapoints = fans ? fans.dataPointList : [];
    const superFanDataPoints = superFans ? superFans.dataPointList : [];

    const fansMap = new Map<string, number>();
    fansDatapoints.forEach((a) => {
        fansMap.set(a.timestamp, a.value);
    });

    const superFansMap = new Map<string, number>();
    superFanDataPoints.forEach((a) => {
        superFansMap.set(a.timestamp, a.value);
    });

    const csvRowData: Trendline[] = [];
    fansMap.forEach((val: number, time: string) => {
        const date = new Date(time);
        let numSuperFans: number;
        if (superFansMap.has(time)) {
            numSuperFans = superFansMap.get(time) as number; //if else statement guarantees number assignment
        } else {
            numSuperFans = 0;
        }
        csvRowData.push([date, val, numSuperFans]);
    });

    //define file name info
    const filenameInfo: FilenameInfo = {
        artistName: artistName,
        startDate: startDate as Date,
        endDate: endDate as Date,
    };
    //download csv
    CsvHelper.downloadCSV("fans", csvRowData, filenameInfo, bundleMap);
}
