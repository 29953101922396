import { Action } from "redux-ts";
import { takeEvery, put, select } from "redux-saga/effects";
import { reportingActions, catalogActions, errorActions } from "../../actions";
import {
    programmingPerformanceRequestPayload,
    reportingOverviewRequestPayload,
    songPerformanceRequestPayload,
    albumPerformanceRequestPayload,
    overviewRequestPayload,
    timeRangePayload,
    trackInfo,
    albumInfo,
    CatalogItemType,
} from "../../../models";
import * as services from "../../../service";
import {
    queryRequest,
    queryResult,
    METRIC_KEYS,
    TimeRange,
} from "../../../models";
import {
    getGranularity,
    generateQueryWithoutGranularity,
    paths,
    DEMOGRAPHICS_ITEM_QUERY_COUNT,
    guessTimeZone,
    hydrationHelpers,
    createSuccessOpsMetricsPayload,
} from "../../../utils";
import { opsMetricsActions } from "../../actions";
import { RootState } from "../../reducers";

export const reportingSagas = [
    watchGetReportsOverview(),
    watchGetReports(),
    watchGetSongPerformance(),
    watchGetAlbumPerformance(),
    watchGetPlaylistPerformance(),
    watchGetStationPerformance(),
    watchGetVoiceReports(),
    watchGetProgrammingReports(),
    watchGetFanTierReports(),
];

// The reports for the overview page
function* getOverviewReports(action: Action<reportingOverviewRequestPayload>) {
    const start = Date.now();
    const functionName = "getOverviewReports";
    try {
        yield put(errorActions.clearError(paths.reports));
        yield put(reportingActions.getReportsOverviewInProgress(true));

        const defaultQuery = generateQueryWithoutGranularity(
            action.payload as timeRangePayload,
            guessTimeZone(),
            300
        );
        const granularity = getGranularity(action.payload.timeRange);

        const request: queryRequest = {
            artistAsin: action.payload.artistAsin,
            topAlbumsQueryV2: {
                ...defaultQuery,
            },
            topTracksQueryV2: {
                ...defaultQuery,
            },
            trackStreamsQueryV2: {
                ...defaultQuery,
                granularity: granularity,
            },
            trackListenersQueryV2: {
                ...defaultQuery,
                granularity: granularity,
            },
            streamSourcesQueryV2: {
                ...defaultQuery,
            },
        };

        const result: queryResult = yield services.getReports({
            query: request,
            teamId: action.payload.teamId,
            requestPath: paths.reports,
        });
        result.responseId = action.payload.timeRange.toString();

        yield put(reportingActions.getReportsOverviewCompleted(result));

        const trackList: trackInfo[] =
            (result.topTracksInfo && result.topTracksInfo.trackInfoList) || [];
        const trackIds: string[] = trackList.map((track) => track.asin);
        const trackTitleSetAsinToGlobalAsinMap: Map<string, string> =
            hydrationHelpers.getTitlesetAsinToGlobalAsinMap(trackList);

        //only hydrate number of asins for overview page
        const trackIdsToHydrate = trackIds.slice(0, 6);
        yield put(
            catalogActions.hydrateAsins({
                asins: trackIdsToHydrate,
                type: CatalogItemType.Tracks,
                titleSetAsinToGlobalAsinMap: trackTitleSetAsinToGlobalAsinMap,
                locale: action.payload.locale,
            })
        );

        // Hydrate albums
        const albumList: albumInfo[] =
            (result.topAlbumsInfo && result.topAlbumsInfo.albumInfoList) || [];
        const albumIds: string[] = albumList.map((album) => album.asin);
        const albumTitleSetAsinToGlobalAsinMap: Map<string, string> =
            hydrationHelpers.getTitlesetAsinToGlobalAsinMap(albumList);

        //only hydrate number of asins for overview page
        const albumIdsToHydrate = albumIds.slice(0, 6);
        yield put(
            catalogActions.hydrateAsins({
                asins: albumIdsToHydrate,
                type: CatalogItemType.Albums,
                titleSetAsinToGlobalAsinMap: albumTitleSetAsinToGlobalAsinMap,
                locale: action.payload.locale,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getReportsOverviewInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

// Reports for voice reports
function* getVoiceReports(action: Action<reportingOverviewRequestPayload>) {
    const start = Date.now();
    const functionName = "getVoiceReports";
    try {
        yield put(errorActions.clearError(paths.voice));
        yield put(reportingActions.getVoiceReportsInProgress(true));

        const defaultQuery = generateQueryWithoutGranularity(
            action.payload as timeRangePayload,
            guessTimeZone(),
            6
        );
        const granularity = getGranularity(action.payload.timeRange);

        const voiceRequests: queryRequest = {
            alexaIndexQuery: {
                ...defaultQuery,
            },
            alexaStreamingDetailsQuery: {
                ...defaultQuery,
            },
            totalVoiceRequestsInfoQuery: {
                ...defaultQuery,
                granularity: granularity,
            },
            tracksVoiceQuery: {
                ...defaultQuery,
            },
            artistAsin: action.payload.artistAsin,
        };

        const voiceResult: queryResult = yield services.getReports({
            query: voiceRequests,
            requestPath: paths.voice,
            teamId: action.payload.teamId,
        });

        voiceResult.responseId = action.payload.timeRange.toString();
        yield put(reportingActions.getVoiceReportsCompleted(voiceResult));

        const voiceTrackIds: string[] = [];
        if (
            voiceResult.topTracksInfo &&
            voiceResult.topTracksInfo.trackInfoList
        ) {
            voiceResult.topTracksInfo.trackInfoList.forEach((track) =>
                voiceTrackIds.push(track.asin)
            );
        }
        yield put(
            catalogActions.hydrateAsins({
                asins: voiceTrackIds,
                type: CatalogItemType.Tracks,
                locale: action.payload.locale,
            })
        );

        yield put(reportingActions.getVoiceReportsInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getVoiceReportsInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: paths.voice,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

// Reports for fan tier reports
function* getFanTierReports(action: Action<reportingOverviewRequestPayload>) {
    const start = Date.now();
    const functionName = "getFanTierReports";
    const granularity = getGranularity(action.payload.timeRange);
    try {
        yield put(errorActions.clearError(paths.fans));
        yield put(reportingActions.getFanTiersReportsInProgress(true));

        const defaultQuery = generateQueryWithoutGranularity(
            action.payload as timeRangePayload,
            guessTimeZone(),
            6
        );

        const fanTiersRequests: queryRequest = {
            fanTiersQuery: {
                ...defaultQuery,
                granularity: granularity,
            },
            trackListenersQueryV2: {
                ...defaultQuery,
                granularity: granularity,
            },
            topCountriesForArtistListenersQuery: {
                ...defaultQuery,
                resultSize: DEMOGRAPHICS_ITEM_QUERY_COUNT,
            },
            topCitiesQuery: {
                ...defaultQuery,
                resultSize: DEMOGRAPHICS_ITEM_QUERY_COUNT,
            },
            artistAsin: action.payload.artistAsin,
        };

        const fanTiersResult: queryResult = yield services.getReports({
            query: fanTiersRequests,
            requestPath: paths.fans,
            teamId: action.payload.teamId,
        });

        fanTiersResult.responseId = action.payload.timeRange.toString();
        yield put(reportingActions.getFanTiersReportsCompleted(fanTiersResult));
        yield put(reportingActions.getFanTiersReportsInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getFanTiersReportsInProgress(false));
        console.log(ex);
        yield put(
            errorActions.handleError({
                requestPath: paths.fans,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

// reports for programming
function* getProgrammingReports(
    action: Action<reportingOverviewRequestPayload>
) {
    const start = Date.now();
    const functionName = "getProgrammingReports";

    try {
        yield put(errorActions.clearError(paths.programming));
        yield put(reportingActions.getProgrammingReportsInProgress(true));

        const defaultQuery = generateQueryWithoutGranularity(
            action.payload as timeRangePayload,
            guessTimeZone(),
            300
        );

        const request: queryRequest = {
            artistAsin: action.payload.artistAsin,
            featuredPlaylistsQueryV2: {
                ...defaultQuery,
            },
            featuredStationsQueryV2: {
                ...defaultQuery,
            },
        };

        const result: queryResult = yield services.getReports({
            query: request,
            requestPath: paths.programming,
            teamId: action.payload.teamId,
        });

        result.responseId = action.payload.timeRange.toString();
        yield put(reportingActions.getProgrammingReportsCompleted(result));

        const loadTime = Date.now() - start;
        console.log("Time it took - Programming: " + loadTime);

        // Hydrate Playlists
        const playlistIds: string[] = [];
        if (
            result.featuredPlaylistsInfo &&
            result.featuredPlaylistsInfo.programmingInfoList
        ) {
            result.featuredPlaylistsInfo.programmingInfoList.forEach(
                (playlist) => playlistIds.push(playlist.id)
            );
        }
        //only hydrate number of asins for programming page
        const playlistIdsToHydrate = playlistIds.slice(0, 6);
        yield put(
            catalogActions.hydrateAsins({
                asins: playlistIdsToHydrate,
                type: CatalogItemType.Playlists,
                locale: action.payload.locale,
            })
        );

        // Hydrate Stations
        const stationIds: string[] = [];
        if (
            result.featuredStationsInfo &&
            result.featuredStationsInfo.programmingInfoList
        ) {
            result.featuredStationsInfo.programmingInfoList.forEach((station) =>
                stationIds.push(station.id)
            );
        }
        //only hydrate number of asins for programming page
        const stationIdsToHydrate = stationIds.slice(0, 6);
        yield put(
            catalogActions.hydrateAsins({
                asins: stationIdsToHydrate,
                type: CatalogItemType.Stations,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getProgrammingReportsInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: paths.programming,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* getReports(action: Action<overviewRequestPayload>) {
    const start = Date.now();
    const functionName = "getReports";
    try {
        yield put(
            reportingActions.getReportsInProgress({
                inProgress: true,
                requestPath: action.payload.requestPath,
            })
        );
        yield put(errorActions.clearError(action.payload.requestPath));

        const existingReport: queryResult | undefined = yield select(
            (state: RootState) =>
                action.payload.requestId &&
                state.reporting.reports.get(action.payload.requestId)
        );

        if (
            action.payload.requestId !== TimeRange.Custom &&
            existingReport &&
            !action.payload.isRefresh
        ) {
            yield put(
                reportingActions.getReportsInProgress({
                    inProgress: false,
                    requestPath: action.payload.requestPath,
                })
            );
            // we already have data, so we can return
            return;
        }

        const result: queryResult = yield services.getReports(action.payload);
        if (action.payload.requestId) {
            result.responseId = action.payload.requestId;
        }

        yield put(
            reportingActions.getReportsCompleted({
                result: result,
                requestPath: action.payload.requestPath,
            })
        );

        // Hydrate left over albums if any
        const albumIds: string[] = [];
        const albumTitleSetAsinToGlobalAsinMap = new Map<string, string>();
        if (result.topAlbumsInfo && result.topAlbumsInfo.albumInfoList) {
            result.topAlbumsInfo.albumInfoList.forEach((album) => {
                albumIds.push(album.asin);
                const globalAsin = album.globalAsin;
                if (globalAsin) {
                    albumTitleSetAsinToGlobalAsinMap.set(
                        album.asin,
                        globalAsin
                    );
                }
            });
        }

        if (albumIds.length > 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: albumIds,
                    type: CatalogItemType.Albums,
                    titleSetAsinToGlobalAsinMap:
                        albumTitleSetAsinToGlobalAsinMap,
                    locale: action.payload.locale,
                })
            );
        }

        // Hydrate left over playlists if any
        const playlistIds =
            result.featuredPlaylistsInfo &&
            result.featuredPlaylistsInfo.programmingInfoList
                ? result.featuredPlaylistsInfo.programmingInfoList.map(
                      (playlist) => playlist.id
                  )
                : [];

        if (playlistIds.length > 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: playlistIds,
                    type: CatalogItemType.Playlists,
                    locale: action.payload.locale,
                })
            );
        }

        // Hydrate left over stations if any
        const stationIds =
            result.featuredStationsInfo &&
            result.featuredStationsInfo.programmingInfoList
                ? result.featuredStationsInfo.programmingInfoList.map(
                      (station) => station.id
                  )
                : [];

        if (stationIds.length > 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: stationIds,
                    type: CatalogItemType.Stations,
                })
            );
        }

        // Hydrate left over tracks
        const trackIds: string[] = [];
        const trackTitleSetAsinToGlobalAsinMap = new Map<string, string>();
        if (result.topTracksInfo && result.topTracksInfo.trackInfoList) {
            result.topTracksInfo.trackInfoList.forEach((track) => {
                trackIds.push(track.asin);
                const globalAsin = track.globalAsin;
                if (globalAsin) {
                    trackTitleSetAsinToGlobalAsinMap.set(
                        track.asin,
                        globalAsin
                    );
                }
            });
        }

        if (trackIds.length > 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: trackIds,
                    type: CatalogItemType.Tracks,
                    titleSetAsinToGlobalAsinMap:
                        trackTitleSetAsinToGlobalAsinMap,
                    locale: action.payload.locale,
                })
            );
        }

        // Hydrate left over voice tracks
        const voiceTrackIds =
            result.tracksVoiceInfo && result.tracksVoiceInfo.trackVoiceInfoList
                ? result.tracksVoiceInfo.trackVoiceInfoList.map(
                      (track) => track.asin
                  )
                : [];

        if (voiceTrackIds.length > 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: voiceTrackIds,
                    type: CatalogItemType.Tracks,
                    locale: action.payload.locale,
                })
            );
        }

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        yield put(
            reportingActions.getReportsInProgress({
                inProgress: false,
                requestPath: action.payload.requestPath,
            })
        );
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* getSongPerformance(action: Action<songPerformanceRequestPayload>) {
    const start = Date.now();
    const functionName = "getSongPerformance";
    try {
        console.log("Dispatch get performance action");
        yield put(reportingActions.getSongPerformanceInProgress(true));
        yield put(errorActions.clearError(action.payload.requestPath));

        const result: queryResult = yield services.getReports(action.payload);

        yield put(
            reportingActions.getSongPerformanceCompleted({
                ...result,
                responseId: action.payload.trackAsin,
            })
        );
        yield put(reportingActions.getSongPerformanceInProgress(false));

        // Hydrate playlist info
        const playlistIds: string[] = [];
        if (
            result.featuredPlaylistsInfo &&
            result.featuredPlaylistsInfo.programmingInfoList
        ) {
            result.featuredPlaylistsInfo.programmingInfoList.forEach(
                (playlist) => playlistIds.push(playlist.id)
            );
        }
        yield put(
            catalogActions.hydrateAsins({
                asins: playlistIds,
                type: CatalogItemType.Playlists,
                locale: action.payload.locale,
            })
        );

        // Hydrate station info
        const stationIds: string[] = [];
        if (
            result.featuredStationsInfo &&
            result.featuredStationsInfo.programmingInfoList
        ) {
            result.featuredStationsInfo.programmingInfoList.forEach((station) =>
                stationIds.push(station.id)
            );
        }
        yield put(
            catalogActions.hydrateAsins({
                asins: stationIds,
                type: CatalogItemType.Stations,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.trackAsin, action.payload.trackAsin],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.query.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getSongPerformanceInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* getAlbumPerformance(action: Action<albumPerformanceRequestPayload>) {
    const start = Date.now();
    const functionName = "getAlbumPerformance";
    try {
        console.log("Dispatch get performance action");
        yield put(reportingActions.getAlbumPerformanceInProgress(true));
        yield put(errorActions.clearError(action.payload.requestPath));

        const result: queryResult = yield services.getReports(action.payload);
        yield put(
            reportingActions.getAlbumPerformanceCompleted({
                ...result,
                responseId: action.payload.albumAsin,
            })
        );
        yield put(reportingActions.getAlbumPerformanceInProgress(false));

        // Hydrate Tracks
        const trackIds: string[] = [];
        if (result.topTracksInfo && result.topTracksInfo.trackInfoList) {
            result.topTracksInfo.trackInfoList.forEach((track) =>
                trackIds.push(track.asin)
            );
        }
        yield put(
            catalogActions.hydrateAsins({
                asins: trackIds,
                type: CatalogItemType.Tracks,
                locale: action.payload.locale,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.albumAsin, action.payload.albumAsin],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.query.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getAlbumPerformanceInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* getPlaylistPerformance(
    action: Action<programmingPerformanceRequestPayload>
) {
    const start = Date.now();
    const functionName = "getPlaylistPerformance";
    try {
        console.log("Dispatch get performance action");
        yield put(reportingActions.getPlaylistPerformanceInProgress(true));
        yield put(errorActions.clearError(action.payload.requestPath));
        const result: queryResult = yield services.getReports(action.payload);
        const trackIds: string[] = [];

        if (result.topTracksInfo && result.topTracksInfo.trackInfoList) {
            result.topTracksInfo.trackInfoList.forEach((track) =>
                trackIds.push(track.asin)
            );
        }

        yield put(
            reportingActions.getPlaylistPerformanceCompleted({
                ...result,
                responseId: action.payload.selectionSourceId,
            })
        );

        yield put(
            catalogActions.hydrateAsins({
                asins: trackIds,
                type: CatalogItemType.Tracks,
                locale: action.payload.locale,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.playlistId, action.payload.selectionSourceId],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.query.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getPlaylistPerformanceInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* getStationPerformance(
    action: Action<programmingPerformanceRequestPayload>
) {
    const start = Date.now();
    const functionName = "getStationPerformance";
    try {
        console.log("Dispatch get station action");
        yield put(reportingActions.getStationPerformanceInProgress(true));
        yield put(errorActions.clearError(action.payload.requestPath));

        const result: queryResult = yield services.getReports(action.payload);
        const trackIds: string[] = [];

        yield put(
            reportingActions.getStationPerformanceCompleted({
                ...result,
                responseId: action.payload.selectionSourceId,
            })
        );

        if (result.topTracksInfo && result.topTracksInfo.trackInfoList) {
            result.topTracksInfo.trackInfoList.forEach((track) =>
                trackIds.push(track.asin)
            );
        }

        yield put(
            catalogActions.hydrateAsins({
                asins: trackIds,
                type: CatalogItemType.Tracks,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.playlistId, action.payload.selectionSourceId],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.artistAsin, action.payload.query.artistAsin],
            [METRIC_KEYS.timeRange, action.payload.timeRange],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(reportingActions.getStationPerformanceInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* watchGetSongPerformance() {
    yield takeEvery(
        reportingActions.getSongPerformance.type,
        getSongPerformance
    );
}

function* watchGetAlbumPerformance() {
    yield takeEvery(
        reportingActions.getAlbumPerformance.type,
        getAlbumPerformance
    );
}

function* watchGetPlaylistPerformance() {
    yield takeEvery(
        reportingActions.getPlaylistPerformance.type,
        getPlaylistPerformance
    );
}

function* watchGetStationPerformance() {
    yield takeEvery(
        reportingActions.getStationPerformance.type,
        getStationPerformance
    );
}

function* watchGetReportsOverview() {
    yield takeEvery(
        reportingActions.getReportsOverview.type,
        getOverviewReports
    );
}

function* watchGetReports() {
    yield takeEvery(reportingActions.getReports.type, getReports);
}

function* watchGetVoiceReports() {
    yield takeEvery(reportingActions.getVoiceReports.type, getVoiceReports);
}

function* watchGetProgrammingReports() {
    yield takeEvery(
        reportingActions.getProgrammingReports.type,
        getProgrammingReports
    );
}

function* watchGetFanTierReports() {
    yield takeEvery(
        reportingActions.getFanTiersReports.type,
        getFanTierReports
    );
}
