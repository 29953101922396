import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Area, Point } from "react-easy-crop/types";
import { isIOS, isMobile } from "react-device-detect";
import moment from "moment";
import { LocalizedUrls } from "@amzn/ziggy-asset";
import { Container, Row, Col, Modal, Toast } from "react-bootstrap";
import styled from "styled-components";
import * as rootStyles from "../../styles";
import {
    artist,
    ErrorPayload,
    TeamPermissions,
    ImageType,
    artistImagePayload,
    hydrateCatalogPayload,
    oAuthClientIds,
    CatalogItemType,
    clientMetricsPayload,
    BundleMap,
    catalogItem,
} from "../../../models";
import {
    RootState,
    userActions,
    profilePageSelector,
    artistImageActions,
    catalogActions,
    clientMetricsActions,
} from "../../../store";
import { stringIds, IconsList, ImageList, bundleIds } from "../../../assets";
import {
    getLocalizedString,
    paths,
    BACKGROUND_IMAGE_MAX_BYTES,
    PROFILE_IMAGE_MAX_BYTES,
    BACKGROUND_IMAGE_MIN_WIDTH,
    PROFILE_IMAGE_MIN_WIDTH,
    IMAGE_COMPRESSION,
    APP_STORE_URL,
    PLAY_STORE_URL,
    getLocalizedUrl,
} from "../../../utils";
import {
    styledTitle,
    Icon,
    CommonHeader,
    Loading,
    ImageCard,
    ArtistGradientBackgroundImage,
    ErrorModal,
} from "../../components";
import "./profile.css";
import {
    UploadModal,
    CropImageModal,
    FullScreenConfirmationModal,
} from "../../components/common/modals";
import { spacers, colors } from "../../styles";

const testIDPrefix = "ProfileScreen";
const metricPrefix = "profilePage";
const imageUploadArtistHandbookUrl = getLocalizedUrl(
    LocalizedUrls.ImageGuidelineUrl
);

type ViewProps = {};

type StateProps = {
    signedIn?: boolean;
    selectedArtistAsin?: string;
    catalog: Map<string, catalogItem>;
    teamId?: string;
    error?: ErrorPayload;
    userPermissions?: TeamPermissions[];
    inProgress?: boolean;
    uploadComplete?: boolean;
    oAuthClientIds?: oAuthClientIds;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    uploadImage: (payload: artistImagePayload) => void;
    hydrateAsin: (payload: hydrateCatalogPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    showUploadProfile: boolean;
    showUploadBackground: boolean;
    showCropProfile: boolean;
    showCropBackground: boolean;
    profileFileRef: React.RefObject<HTMLInputElement>;
    backgroundFileRef: React.RefObject<HTMLInputElement>;
    showUploadProfileSuccess: boolean;
    showUploadBackgroundSuccess: boolean;
    showLoading: boolean;
    showProfileAlert: boolean;
    profileAlertText?: string;
    showBackgroundAlert: boolean;
    backgroundAlertText?: string;
    croppedAreaPixels?: Area;
    croppedImageUrl?: string;
    selectedImage?: any;
    crop: Point;
    zoom: number;
    cookie?: any;
    showError: boolean;
};

class ProfileScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showCropProfile: false,
            showUploadBackground: false,
            showUploadProfile: false,
            showCropBackground: false,
            showUploadBackgroundSuccess: false,
            showUploadProfileSuccess: false,
            showLoading: false,
            showProfileAlert: false,
            showBackgroundAlert: false,
            profileFileRef: React.createRef(),
            backgroundFileRef: React.createRef(),
            crop: { x: 0, y: 0 },
            zoom: 1,
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.updateCurrentPath(window.location.pathname);

        if (!this.props.selectedArtistAsin || !this.props.teamId) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // If we get an error, display it
        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }
        if (!this.props.selectedArtistAsin || !this.props.signedIn) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        // When uploading no longer in progress
        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({
                showLoading: false,
            });
        }

        // When background image uploading no longer in progress
        if (
            prevProps.inProgress &&
            !this.props.inProgress &&
            this.state.showCropBackground &&
            this.props.uploadComplete
        ) {
            this.setState({
                showCropBackground: false,
                showUploadBackgroundSuccess: true,
            });
        }

        // When profile image uploading no longer in progress
        if (
            prevProps.inProgress &&
            !this.props.inProgress &&
            this.state.showCropProfile &&
            this.props.uploadComplete
        ) {
            this.setState({
                showCropProfile: false,
                showUploadProfileSuccess: true,
            });
        }
    }

    render() {
        const hydratedArtist = this.props.catalog.get(
            this.props.selectedArtistAsin || ""
        ) as artist;

        const time = moment();
        if (
            hydratedArtist &&
            hydratedArtist.asin &&
            time.isAfter(hydratedArtist.ttl)
        ) {
            this.props.hydrateAsin({
                asins: [this.props.selectedArtistAsin || ""],
                type: CatalogItemType.Artists,
            });
        }

        const hasUploadImagePermission = this.props.userPermissions?.includes(
            TeamPermissions.UploadImage
        );

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ArtistGradientBackgroundImage artist={hydratedArtist} />

                <CommonHeader
                    title={
                        hydratedArtist?.title ||
                        getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ACCOUNT_STRINGS,
                            stringId: stringIds.Account.overview,
                        })
                    }
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.artist,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideDatePicker={true}
                />

                {/* IMAGE SECTION */}
                <Row style={{ marginTop: rootStyles.spacers.large }}>
                    <Col>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROFILE_STRINGS,
                                stringId: stringIds.Profile.detail,
                            })}
                        </styledTitle.h4>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginBottom: rootStyles.spacers.medium,
                    }}
                >
                    <Col>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROFILE_STRINGS,
                                stringId: stringIds.Profile.description,
                            })}
                        </span>
                    </Col>
                </Row>

                {isMobile && (
                    <Row>
                        <Col>
                            <Toast style={toastContainerStyle}>
                                <Toast.Body style={toastBodyStyle}>
                                    <p style={textStyle}>
                                        <a
                                            href={
                                                isIOS
                                                    ? APP_STORE_URL
                                                    : PLAY_STORE_URL
                                            }
                                            style={hrefTextStyle}
                                        >
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.PROFILE_STRINGS,
                                                    stringId:
                                                        stringIds.Profile
                                                            .downloadApp,
                                                }
                                            ) + " "}
                                        </a>
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.PROFILE_STRINGS,
                                                stringId:
                                                    stringIds.Profile.update,
                                            }
                                        )}
                                    </p>
                                </Toast.Body>
                            </Toast>
                        </Col>
                    </Row>
                )}

                {!isMobile && (
                    <Row
                        style={{
                            maxWidth: 800,
                            gap: rootStyles.spacers.medium,
                            paddingLeft: 15, // matches bootstrap default applied to the rest of the page
                            paddingRight: 15, // matches bootstrap default applied to the rest of the page
                        }}
                    >
                        <FlexibleCol>
                            <ImageCard
                                cardStyle={defaultButtonStyle}
                                image={hydratedArtist?.images?.artSmall}
                                imageFallback={ImageList.placeholder_artist}
                                imagePrimaryText={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.PROFILE_STRINGS,
                                        stringId:
                                            stringIds.Profile.profilePhoto,
                                    }
                                )}
                                imageSecondaryText={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.PROFILE_STRINGS,
                                        stringId:
                                            stringIds.Profile
                                                .profileDescription,
                                    }
                                )}
                                rightIcon={
                                    !!hasUploadImagePermission
                                        ? IconsList.action_edit
                                        : undefined
                                }
                                testIDPrefix={testIDPrefix + "_ProfileImage"}
                                onClick={
                                    hasUploadImagePermission
                                        ? this.showUploadProfile
                                        : undefined
                                }
                            />
                        </FlexibleCol>
                        <FlexibleCol>
                            {hydratedArtist?.backgroundImages && (
                                <ImageCard
                                    cardStyle={defaultButtonStyle}
                                    image={
                                        hydratedArtist?.backgroundImages
                                            ?.artSmall
                                    }
                                    imageFallback={ImageList.placeholder_artist}
                                    imageStyle={
                                        rootStyles.imageStyles.miniSquareImage
                                    }
                                    imagePrimaryText={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.PROFILE_STRINGS,
                                            stringId:
                                                stringIds.Profile.background,
                                        }
                                    )}
                                    imageSecondaryText={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.PROFILE_STRINGS,
                                            stringId:
                                                stringIds.Profile
                                                    .backgroundDescription,
                                        }
                                    )}
                                    rightIcon={
                                        !!hasUploadImagePermission
                                            ? IconsList.action_edit
                                            : undefined
                                    }
                                    testIDPrefix={
                                        testIDPrefix + "_BackgroundImage"
                                    }
                                    onClick={
                                        hasUploadImagePermission
                                            ? this.showUploadBackground
                                            : undefined
                                    }
                                />
                            )}
                            {!hydratedArtist?.backgroundImages && (
                                <div
                                    id={`${testIDPrefix}_BackgroundLabel${
                                        hasUploadImagePermission
                                            ? ""
                                            : "NoPermission"
                                    }`}
                                    style={{
                                        ...defaultButtonStyle,
                                        cursor: hasUploadImagePermission
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={
                                        hasUploadImagePermission
                                            ? () =>
                                                  this.setState({
                                                      showUploadBackground:
                                                          true,
                                                  })
                                            : undefined
                                    }
                                >
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            height: 56,
                                        }}
                                    >
                                        {hasUploadImagePermission && (
                                            <Col xs={3}>
                                                <div
                                                    style={
                                                        defaultIconContainerStyle
                                                    }
                                                >
                                                    <Icon
                                                        size={24}
                                                        color={"white"}
                                                        icon={
                                                            IconsList.action_add
                                                        }
                                                        id={`${testIDPrefix}_BackgroundImageAdd`}
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                        <Col>
                                            <Row>
                                                <span
                                                    style={{
                                                        fontSize:
                                                            rootStyles
                                                                .webFontSizes
                                                                .t1,
                                                        textTransform:
                                                            "capitalize",
                                                        margin: 0,
                                                        padding: 0,
                                                        color: rootStyles.colors
                                                            .primary,
                                                    }}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ACCOUNT_STRINGS,
                                                            stringId:
                                                                hasUploadImagePermission
                                                                    ? stringIds
                                                                          .Account
                                                                          .backgroundImage
                                                                    : stringIds
                                                                          .Account
                                                                          .noBackground,
                                                        }
                                                    )}
                                                </span>
                                            </Row>
                                            <Row>
                                                <span
                                                    style={{
                                                        ...rootStyles.textStyles
                                                            .secondary,
                                                        marginTop:
                                                            rootStyles.spacers
                                                                .micro,
                                                    }}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ACCOUNT_STRINGS,
                                                            stringId:
                                                                hasUploadImagePermission
                                                                    ? stringIds
                                                                          .Account
                                                                          .uploadBackground
                                                                    : stringIds
                                                                          .Account
                                                                          .contactAdmin,
                                                        }
                                                    )}
                                                </span>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </FlexibleCol>
                    </Row>
                )}

                <UploadModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.label,
                    })}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadProfile,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.file,
                    })}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.select,
                    })}
                    uploadButtonAction={this.onSelectProfile}
                    onDismiss={this.onDismissUploadProfile}
                    isVisible={this.state.showUploadProfile}
                    id={testIDPrefix + "_UploadProfileModal"}
                />

                <input
                    accept=".jpeg,.jpg,.png"
                    ref={this.state.profileFileRef}
                    type="file"
                    style={{ display: "none" }}
                    value={""}
                    onChange={this.cropProfile}
                />

                <UploadModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.label,
                    })}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadBackground,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.backgroundFile,
                    })}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.select,
                    })}
                    uploadButtonAction={this.onSelectBackground}
                    onDismiss={this.onDismissUploadBackground}
                    isVisible={this.state.showUploadBackground}
                    id={testIDPrefix + "_UploadBackgroundModal"}
                />

                <input
                    accept=".jpeg,.jpg,.png"
                    ref={this.state.backgroundFileRef}
                    type="file"
                    style={{ display: "none" }}
                    value={""}
                    onChange={this.cropBackground}
                />

                <CropImageModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.instruction,
                    })}
                    cropShape="round"
                    image={this.state.selectedImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={1}
                    onZoomIn={this.onZoomIn}
                    onZoomOut={this.onZoomOut}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onProfileCropComplete}
                    showAlert={this.state.showProfileAlert}
                    alertText={this.state.profileAlertText}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadProfile,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNote,
                    })}
                    terms={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.terms,
                    })}
                    guidelines={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNoteNext,
                    })}
                    artistHandbookDescritpion={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.ACCOUNT_STRINGS,
                            stringId: stringIds.Account.artistHandbook,
                        }
                    )}
                    artistHandbookUrl={imageUploadArtistHandbookUrl}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.selectNew,
                    })}
                    uploadButtonAction={this.onSelectProfile}
                    saveButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.upload,
                    })}
                    saveButtonAction={() =>
                        this.uploadCroppedImage(ImageType.ProfileImage)
                    }
                    onDismiss={this.onDismissCropProfile}
                    isVisible={this.state.showCropProfile}
                    id={testIDPrefix + "_CropProfileImageModal"}
                />

                <CropImageModal
                    labelText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.instruction,
                    })}
                    cropShape="rect"
                    image={this.state.selectedImage}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={4 / 3}
                    onZoomIn={this.onZoomIn}
                    onZoomOut={this.onZoomOut}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onBackgroundCropComplete}
                    showAlert={this.state.showBackgroundAlert}
                    alertText={this.state.backgroundAlertText}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.uploadBackground,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNote,
                    })}
                    terms={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.terms,
                    })}
                    guidelines={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.uploadNoteNext,
                    })}
                    artistHandbookDescritpion={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.ACCOUNT_STRINGS,
                            stringId: stringIds.Account.artistHandbook,
                        }
                    )}
                    artistHandbookUrl={imageUploadArtistHandbookUrl}
                    uploadButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.selectNew,
                    })}
                    uploadButtonAction={this.onSelectBackground}
                    saveButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.upload,
                    })}
                    saveButtonAction={() =>
                        this.uploadCroppedImage(ImageType.BackgroundImage)
                    }
                    onDismiss={this.onDismissCropBackground}
                    isVisible={this.state.showCropBackground}
                    id={testIDPrefix + "_CropBackgroundImageModal"}
                />

                <Modal
                    show={this.state.showLoading}
                    style={rootStyles.containerStyles.modal}
                    size="lg"
                >
                    <Modal.Body>
                        <Loading />
                    </Modal.Body>
                </Modal>

                <FullScreenConfirmationModal
                    imageSource={this.state.croppedImageUrl}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.success,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.profileSuccess,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.PROFILE_STRINGS,
                            stringId: stringIds.Profile.close,
                        }
                    )}
                    confirmButtonAction={this.onConfirmUploadProfileSuccess}
                    onDismiss={this.onConfirmUploadProfileSuccess}
                    isVisible={this.state.showUploadProfileSuccess}
                    id={testIDPrefix + "_ProfileSuccessConfirmationModal"}
                />

                <FullScreenConfirmationModal
                    imageSource={this.state.croppedImageUrl}
                    imageStyle={rectImage}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.success,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROFILE_STRINGS,
                        stringId: stringIds.Profile.backgroundSuccess,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.PROFILE_STRINGS,
                            stringId: stringIds.Profile.close,
                        }
                    )}
                    confirmButtonAction={this.onConfirmUploadBackgroundSuccess}
                    onDismiss={this.onConfirmUploadBackgroundSuccess}
                    isVisible={this.state.showUploadBackgroundSuccess}
                    id={testIDPrefix + "_BackgroundSuccessConfirmationModal"}
                />
                <ErrorModal
                    isVisible={this.state.showError}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ERRORS_STRINGS,
                        stringId: stringIds.Errors.errorTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ACCOUNT_STRINGS,
                        stringId: stringIds.Account.imageError,
                    })}
                    onDismiss={this.dismissError}
                />
            </Container>
        );
    }

    private onZoomIn = () => {
        this.setState({ zoom: this.state.zoom + 0.2 });
    };

    private onZoomOut = () => {
        const newZoom = this.state.zoom - 0.2;

        if (newZoom >= 1) {
            this.setState({ zoom: newZoom });
        }
    };

    private onConfirmUploadProfileSuccess = () => {
        this.setState({ showUploadProfileSuccess: false });
    };

    private onConfirmUploadBackgroundSuccess = () => {
        this.setState({ showUploadBackgroundSuccess: false });
    };

    private showUploadProfile = () => {
        this.setState({ showUploadProfile: true });
    };

    private showUploadBackground = () => {
        this.setState({ showUploadBackground: true });
    };

    private onDismissUploadProfile = () => {
        this.setState({ showUploadProfile: false });
    };

    private onDismissUploadBackground = () => {
        this.setState({ showUploadBackground: false });
    };

    private onDismissCropProfile = () => {
        this.setState({ showCropProfile: false });
    };

    private onDismissCropBackground = () => {
        this.setState({ showCropBackground: false });
    };

    private onSelectProfile = () => {
        this.state.profileFileRef.current &&
            this.state.profileFileRef.current.click();
    };

    private cropProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        const file = event.target.files && event.target.files[0];

        fileReader.onloadend = () => {
            this.setState({
                selectedImage: fileReader.result,
                showCropProfile: true,
                showUploadProfile: false,
            });
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    private cropBackground = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        const file = event.target.files && event.target.files[0];

        fileReader.onloadend = () => {
            this.setState({
                selectedImage: fileReader.result,
                showCropBackground: true,
                showUploadBackground: false,
            });
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    private onCropChange = (crop: Point) => {
        this.setState({ crop: crop });
    };

    private onZoomChange = (zoom: number) => {
        this.setState({ zoom: zoom });
    };

    private onProfileCropComplete = (
        _croppedArea: Area,
        croppedAreaPixels: Area
    ) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels,
            showProfileAlert: croppedAreaPixels.width < PROFILE_IMAGE_MIN_WIDTH,
            profileAlertText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.PROFILE_STRINGS,
                stringId: stringIds.Profile.profileResolutionAlert,
            }),
        });
    };

    private onBackgroundCropComplete = (
        _croppedArea: Area,
        croppedAreaPixels: Area
    ) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels,
            showBackgroundAlert:
                croppedAreaPixels.width < BACKGROUND_IMAGE_MIN_WIDTH,
            backgroundAlertText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.PROFILE_STRINGS,
                stringId: stringIds.Profile.backgroundResolutionAlert,
            }),
        });
    };

    private onSelectBackground = () => {
        this.state.backgroundFileRef.current &&
            this.state.backgroundFileRef.current.click();
    };

    private uploadCroppedImage = (imageType: ImageType) => {
        this.setState({ showLoading: true });
        const image = this.state.selectedImage;
        const crop = this.state.croppedAreaPixels;

        if (!crop || !image) {
            return;
        }

        const croppedImage = this.getCroppedImg(image, crop);
        this.setState({ croppedImageUrl: croppedImage?.toDataURL() });

        croppedImage &&
            croppedImage.toBlob(
                (blob) => {
                    if (
                        !blob ||
                        !blob.type ||
                        !this.props.selectedArtistAsin ||
                        !this.props.teamId
                    ) {
                        return;
                    }

                    if (
                        imageType == ImageType.BackgroundImage &&
                        blob.size > BACKGROUND_IMAGE_MAX_BYTES
                    ) {
                        this.setState({
                            showLoading: false,
                            showBackgroundAlert: true,
                            backgroundAlertText: getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.PROFILE_STRINGS,
                                    stringId:
                                        stringIds.Profile.backgroundSizeAlert,
                                }
                            ),
                        });
                    } else if (
                        imageType == ImageType.ProfileImage &&
                        blob.size > PROFILE_IMAGE_MAX_BYTES
                    ) {
                        this.setState({
                            showLoading: false,
                            showProfileAlert: true,
                            profileAlertText: getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.PROFILE_STRINGS,
                                    stringId:
                                        stringIds.Profile.profileSizeAlert,
                                }
                            ),
                        });
                    } else {
                        const payload: artistImagePayload = {
                            asin: this.props.selectedArtistAsin,
                            fileExtension: blob?.type,
                            imageFile: blob,
                            imageType: imageType,
                            teamId: this.props.teamId,
                            requestPath: paths.profile,
                        };

                        this.props.uploadImage(payload);
                    }
                },
                "image/jpeg",
                IMAGE_COMPRESSION
            );
    };

    private getCroppedImg(imageSrc: string, pixelCrop: Area) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = document.createElement("img");
        image.src = imageSrc;

        canvas.width = image.width;
        canvas.height = image.height;

        if (!ctx) {
            return;
        }

        ctx.drawImage(image, 0, 0);
        const data = ctx.getImageData(0, 0, image.width, image.height);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

        return canvas;
    }

    private dismissError = () => {
        this.setState({ showError: false });
    };
}

const toastContainerStyle: React.CSSProperties = {
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    width: 350,
    maxWidth: 350,
    height: 64,
    borderRadius: 8,
    backgroundColor: "rgba(32, 53, 59, 1)",
};

const toastBodyStyle: React.CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
};

const textStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    textTransform: "none",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
};

const hrefTextStyle: React.CSSProperties = {
    color: colors.accent,
};

const defaultButtonMaxWidth: React.CSSProperties = {
    maxWidth: 350,
};

const defaultButtonStyle: React.CSSProperties = {
    ...defaultButtonMaxWidth,
    backgroundColor: rootStyles.glassColors.primary2,
    padding: rootStyles.spacers.medium,
    marginLeft: 0,
    minHeight: 96,
    borderRadius: 8,
    justifyContent: "center",
    flexDirection: "row",
};

const rectImage: React.CSSProperties = {
    marginTop: spacers.medium,
    width: 400,
    height: 300,
    marginBottom: spacers.medium,
};

const defaultIconContainerStyle: React.CSSProperties = {
    height: 48,
    width: 48,
    paddingTop: 10,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 24,
    marginRight: rootStyles.spacers.medium,
    backgroundColor: rootStyles.glassColors.primary2,
};

const rowStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
    borderBottomStyle: "solid",
    paddingBottom: 0,
    maxHeight: 70,
    marginBottom: 10,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
};

const spanStyle: React.CSSProperties = {
    fontSize: rootStyles.webFontSizes.t1,
    textTransform: "capitalize",
    margin: 0,
    padding: 0,
    flex: 2,
    color: rootStyles.colors.primary,
};

const FlexibleCol = styled.div`
    max-width: 350px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${rootStyles.spacers.medium}px;
    padding: 0;
    flex-basis: max-content;
    flex-shrink: 0;
    position: relative;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        flex-shrink: 1;
    }
    @media (min-width: ${rootStyles.breakpoints.lg}px) {
        min-width: 350px;
    }
`;

function mapStateToProps(state: RootState): StateProps {
    return profilePageSelector(state, paths.profile);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        uploadImage: (payload: artistImagePayload) =>
            dispatch(artistImageActions.artistUpload(payload)),
        hydrateAsin: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfileScreen)
);
