import _ from "lodash";
import { CatalogState } from "./../../reducers";
import {
    catalog,
    programmingInfo,
    CatalogItemType,
    playlist,
    BundleMap,
} from "../../../models/";
import {
    COULD_NOT_LOAD_DATA_SUFFIX,
    getLocalizedString,
    iterativeHydrationCall,
} from "../../../utils/";
import { CsvHelper, FilenameInfo, Playlist } from "./../../../export/";
import { stringIds, bundleIds } from "../../../assets";

export async function createPlaylistsCsvFile({
    startDate,
    endDate,
    playlistData,
    teamId,
    catalogState,
    locale,
    artistName,
    contentName,
    requestPath,
    bundleMap,
}: {
    startDate: Date;
    endDate: Date;
    playlistData: programmingInfo[];
    teamId: string;
    catalogState: CatalogState;
    locale: string;
    artistName: string;
    contentName?: string;
    requestPath: string;
    bundleMap: BundleMap;
}) {
    const start = Date.now();
    const asins = playlistData.map((track) => track.id);

    const hydrationPayload = {
        catalogState: catalogState,
        hydrationType: CatalogItemType.Playlists,
        asins: asins,
        teamId: teamId,
        locale: locale,
    };
    const compositeCatalog: catalog = await iterativeHydrationCall(
        hydrationPayload
    );

    //define csv row data
    let csvRowData: Playlist[] = [];

    playlistData.forEach((playlist) => {
        let playlistTitle: string | undefined = compositeCatalog.get(
            playlist.id
        )?.title;
        let playlistTerritories: string[] | undefined = (
            compositeCatalog.get(playlist.id) as playlist
        )?.territories; // if statement guarantees assignment to playlistTerritories
        if (!playlistTerritories) {
            playlistTerritories = [
                getLocalizedString(bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.missingCatalogDataTitle,
                }),
            ];
        }
        csvRowData.push([
            playlistTitle ||
                getLocalizedString(bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.missingCatalogDataTitle,
                }),
            playlistTerritories.join("/"),
            playlist.streams,
        ]);
    });

    //define file name info
    const filenameInfo: FilenameInfo = {
        artistName: artistName,
        contentName: contentName,
        startDate: startDate as Date,
        endDate: endDate as Date,
    };

    //download csv
    CsvHelper.downloadCSV(
        "featuredInPlaylists",
        csvRowData,
        filenameInfo,
        bundleMap
    );
}
