import { Action } from "redux-ts";
import { put, select, takeEvery } from "redux-saga/effects";
import {
    errorActions,
    merchHydratorActions,
    opsMetricsActions,
} from "../../actions";
import { MerchHydratorState, RootState } from "../../reducers";
import {
    hydrateMerchPayload,
    hydrateMerchResponse,
    merchCatalog,
    merchIdentifiers,
    METRIC_KEYS,
} from "../../../models";
import * as services from "../../../service";
import { MERCH_HYDRATION_BATCH_SIZE } from "../../../view/components";
import {
    checkIfNotEmpty,
    createSuccessOpsMetricsPayload,
} from "../../../utils";
import _ from "lodash";

export const hydrateMerchSagas = [watchGetHydratedMerch()];

function* getHydratedMerch(action: Action<hydrateMerchPayload>) {
    const start = Date.now();
    const functionName = "getHydratedMerch";
    try {
        if (
            !action.payload.request.identifiers ||
            action.payload.request.identifiers.length === 0
        ) {
            return;
        }

        const merchHydratorState: MerchHydratorState = yield select(
            (state: RootState) => state.merchHydrator
        );
        const catalog: merchCatalog = merchHydratorState.merchCatalog;
        const hydratingIdentifiers: merchIdentifiers[] =
            merchHydratorState.hydratingIdentifiers;
        let ids = [
            ...new Set(
                action.payload.request.identifiers.filter((i) => {
                    const asin = i.itemAsin;
                    return (
                        asin !== "" &&
                        checkIfNotEmpty(asin) &&
                        !catalog.has(asin) &&
                        !hydratingIdentifiers.includes(asin)
                    );
                })
            ),
        ];

        if (ids.length === 0) {
            return;
        }

        ids = _.slice(ids, 0, MERCH_HYDRATION_BATCH_SIZE);
        yield put(
            merchHydratorActions.hydrateMerchHydratingIdentifiers(
                ids.map((id) => id.itemAsin)
            )
        );

        const hydrateRequest = action.payload.request;
        hydrateRequest.identifiers = ids;
        const response: hydrateMerchResponse = yield services.getHydratedMerch(
            hydrateRequest
        );
        yield put(merchHydratorActions.hydrateMerchCompleted(response.data));
        yield put(
            merchHydratorActions.hydrateMerchFailedIdentifiers(
                response.failedIdentifiers.map((i) => i.itemAsin)
            )
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            merchHydratorActions.hydrateMerchFailedIdentifiers(
                action.payload.request.identifiers.map((i) => i.itemAsin)
            )
        );

        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.page, action.payload.requestPath],
        ]);
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
                silent: true,
            })
        );
    }
}

function* watchGetHydratedMerch() {
    yield takeEvery(merchHydratorActions.hydrateMerch.type, getHydratedMerch);
}
