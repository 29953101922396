import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import {
    Table,
    IncrementalLoading,
    Loading,
    InlineError,
    CommonHeader,
} from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingState,
    RequiredReportingDispatchProps,
    TableRowProps,
    queryRequest,
    trackInfo,
    TimeRange,
    hydrateCatalogPayload,
    overviewRequestPayload,
    timeRangePayload,
    CatalogItemType,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    EntityType,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    songsScreenSelector,
    reportingActions,
    catalogActions,
    userActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import {
    getLocalizedString,
    generateSongRows,
    paths,
    HYDRATION_COUNT,
    generateQueryWithoutGranularity,
    guessTimeZone,
    hydrationHelpers,
    buildUIClickPayload,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { Container, Row, Col } from "react-bootstrap";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "SongsScreen";
const metricPrefix = "songsPage";

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    tracks: trackInfo[];
    albumTracks: trackInfo[];
    voiceTracks: trackInfo[];
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
};
type DispatchProps = RequiredReportingDispatchProps & {
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    loadedCount: number;
};

class SongsScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }
        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();

        this.getReports();
        this.loadMore();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports(true);
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports(true);
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
            this.loadMore();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }
    }

    render() {
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }
        const tracksInfo = this.props.tracks.slice(0, this.state.loadedCount);
        const title = getLocalizedString(this.props.bundleMap, {
            bundleId: bundleIds.REPORTS_STRINGS,
            stringId: stringIds.Reports.topSongsTitle,
        });
        const subtitle = getLocalizedString(this.props.bundleMap, {
            bundleId: bundleIds.REPORTS_STRINGS,
            stringId: stringIds.Reports.topSongsSubtitle,
        });
        const topSongsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.songsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];

        const topSongs: TableRowProps[] = generateSongRows(
            tracksInfo,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            this.props.bundleMap,
            testIDPrefix + "_SongsRows"
        );

        const topSongsCsvPayload: createCsvFileRequestPayload = {
            csvExportType: "topSongs",
            csvButtonId: CsvButtonId.TopSongsTopSongs,
            trackData: this.props.tracks,
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: paths.songs,
        };

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* {this.props.artist &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.artist?.images?.artSmall }}
                    />
                } */}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <CommonHeader
                    title={title}
                    subtitle={subtitle}
                    id={`${testIDPrefix}_Header`}
                    showCsvButton={
                        this.props.hasCsvExportCapabilities &&
                        !!this.props.tracks.length
                    }
                    csvDownload={() => {
                        this.props.sendClientMetrics(
                            buildUIClickPayload(
                                buttonIds.CsvExport.downloadSongs,
                                pageIds.songs,
                                this.props.selectedArtist,
                                EntityType.ARTIST
                            )
                        );
                        this.props.getCsvFile(topSongsCsvPayload);
                    }}
                    csvTooltipMessage={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.CSV_STRINGS,
                            stringId: stringIds.Csv.TooltipMessage.TopSongs,
                        }
                    )}
                    csvButtonLoading={
                        this.props.csvButtonLoadingStatus["TopSongs-TopSongs"]
                    }
                />
                <Row>
                    <Col>
                        <Table
                            loading={this.state.refreshing}
                            loadMore={this.loadMore}
                            rows={topSongs}
                            labels={topSongsHeader}
                            footer={
                                this.props.tracks.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                            id={testIDPrefix + "_SongsTable"}
                            showIndex={true}
                            scrolling={true}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    private loadMore = () => {
        if (!this.props.signedIn) {
            return;
        }

        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            this.props.tracks,
            this.props.hydrateAsins,
            CatalogItemType.Tracks,
            this.props.userLocale
        );

        this.setState({
            loadedCount: nextCount,
        });
    };

    private getReports = (isRefresh?: boolean) => {
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        this.setState({
            loadedCount: HYDRATION_COUNT,
        });

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
            topTracksQueryV2: generateQueryWithoutGranularity(
                timeRangePayload,
                guessTimeZone(),
                300
            ),
        };

        const requestId = `seeAll_topTracks_${this.props.selectedRange.toString()}`;

        // Dispatch request for overview
        const payload: overviewRequestPayload = {
            query: query,
            requestPath: paths.songs,
            requestId: requestId,
            isRefresh: isRefresh,
            teamId: this.props.teamId,
        };

        this.props.getReport(payload);
    };
}

function mapStateToProps(state: RootState) {
    return songsScreenSelector(state, paths.songs);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReport: (payload: overviewRequestPayload) =>
            dispatch(reportingActions.getReports(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SongsScreen)
);
