import {
    artist,
    teamInfo,
    TeamType,
    cookieStrings,
    selectArtistPayload,
} from "../models";
import Cookies from "js-cookie";
import { privilegedTeamId } from "../service";
import { paths } from ".";
import { RouteComponentProps } from "react-router";

export type SelectArtistProps = {
    signedIn?: boolean;
    selectedArtist?: artist;
    teams?: teamInfo[];
    path: string;
    metricPrefix: string;
    artistTeamInfo?: teamInfo;
    isPrivileged?: boolean;
    historyProps?: RouteComponentProps; // only optional for testing. you must pass this for a redirect.
    artistSelect: (payload: selectArtistPayload) => void;
    clearArtistSearch?: () => void;
};

/**
 * This function is called from both ClaimThisArtistScreen and SelectArtistScreen.
 * It will select the given artist and then direct the user to the Reports Overview page for that artist.
 *
 * historyProps is option, but purely for testing purposes.
 * If you want the web page to redirect to the reports overview screen, then you must fill in the historyProps.
 *
 * @param props
 * @returns true = artist was successfully selected. false = artist was not selected.
 */
export function onArtistSelected(props: SelectArtistProps) {
    if (
        (!props.signedIn && props.clearArtistSearch) || // checks if the user is signed in and if there is a callback function to clearArtistSearch
        !props.selectedArtist || // and if there is a selected artist passed in
        !props.teams
    ) {
        // and if there are some teams that this user has access to
        return false;
    }

    // if clearArtistSearch is given, call it. should only be used from SelectArtistScreen
    if (props.clearArtistSearch) {
        props.clearArtistSearch();
    }

    /* if there is no artist team passed in, go find it.
     * This is here to save a little bit of time because we will already have the artist team in ClaimThisArtistScreen, but not in SelectArtistScreen.
     */
    if (!props.artistTeamInfo) {
        props.artistTeamInfo = props.teams.find(
            (team) =>
                props.selectedArtist?.asin &&
                team.artistAsins.includes(props.selectedArtist.asin)
        );
    }

    let teamId;

    if (!props.artistTeamInfo) {
        if (props.isPrivileged) {
            teamId = privilegedTeamId;
        } else {
            // if no artist team was found, check for vendor or label team
            const vendorTeam: teamInfo | undefined = props.teams.find(
                (team) =>
                    team.teamType === TeamType.VENDOR ||
                    team.teamType === TeamType.LABEL
            );
            if (vendorTeam) {
                Cookies.set(
                    cookieStrings.userId,
                    vendorTeam &&
                        vendorTeam.members &&
                        vendorTeam.members[0].memberId
                );
                teamId = vendorTeam.teamId;
            } else {
                return false; // no team found
            }
        }
    } else {
        // artist team was found.
        teamId = props.artistTeamInfo.teamId;
        Cookies.set(
            cookieStrings.userId,
            props.artistTeamInfo &&
                props.artistTeamInfo.members &&
                props.artistTeamInfo.members[0].memberId
        );
    }

    let historyPath = paths.reports;
    if (props.path !== paths.artistSelect) {
        historyPath = props.path;
    }

    const payload: selectArtistPayload = {
        artist: props.selectedArtist,
        teamId: teamId,
        requestPath: props.path,
        reroutePath: historyPath,
        historyProps: props.historyProps,
    };

    props.artistSelect(payload);
    return true; // artist successfully selected
}
