import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { Dropdown, Col, Row, Container } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import {
    TeamRole,
    TeamType,
    teamInfo,
    JobTitle,
    teamManagementMember,
    TeamPermissions,
    artist,
    inviteTeamMemberPayload,
    setPermissionsPayload,
    setMemberInfoPayload,
    removeMemberPayload,
    promoteToOwnerPayload,
    cancelInvitePayload,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    getLocalizedString,
    translateJobTitleToString,
    paths,
    GENERIC_TEXT_FIELD_LIMIT,
    emailIsValid,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    TextField,
    RadioList,
    LargeSolidButton,
    styledTitle,
    CommonHeader,
} from "../../components";
import {
    teamManagementActions,
    RootState,
    ModifyMemberType,
    inviteTeamMembersScreenSelector,
    userActions,
} from "../../../store";
import { dropDownStyles } from "../../styles/buttonStyles";
import { EmailValidator } from "commons-validator-js";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "InviteTeamMemberScreen";
const metricPrefix = "inviteTeamMemberPage";
const INVITE_STATE = "inviteState";

type ViewProps = {
    memberId: string;
};

type StateProps = {
    teamMembers: teamManagementMember[];
    error?: string;
    inviteSent: boolean;
    modifyMemberInProgress: boolean;
    modifyMemberSuccess: boolean;
    userRole?: TeamRole;
    userPermissions?: TeamPermissions[];
    teamId: string;
    modifyType: ModifyMemberType;
    artist?: artist;
    team: teamInfo;
    userId?: string;
    signedIn: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    submitInvite: (data: inviteTeamMemberPayload) => void;
    submitUpdatePermissions: (data: setPermissionsPayload) => void;
    submitUpdateMemberInfo: (data: setMemberInfoPayload) => void;
    submitRemove: (data: removeMemberPayload) => void;
    submitPromoteToOwner: (data: promoteToOwnerPayload) => void;
    submitCancelInvite: (data: cancelInvitePayload) => void;
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    name: string;
    email: string;
    jobTitle: JobTitle;
    company?: string;
    artist?: artist;
    showPromoteToOwnerModal: boolean;
    selectedAccessIndex?: number;
    modifyInProgress: boolean;
    modifySuccess: false;
    showPermissionsList: boolean;
    modifyType: ModifyMemberType;
    selectedMember?: teamManagementMember;
};

const AdminAccessIndex = 0;
const DataAccessIndex = 1;

class InviteTeamMemberScreen extends React.Component<Props, State> {
    private jobTitles = [JobTitle.Artist, JobTitle.Manager, JobTitle.LabelRep];

    constructor(props: any) {
        super(props);

        const inviteState = localStorage.getItem(this.getInviteId());
        const storedState = inviteState && JSON.parse(inviteState);

        this.state = storedState
            ? storedState
            : {
                  name: "",
                  email: "",
                  jobTitle: JobTitle.Manager,
                  showPromoteToOwnerModal: false,
                  company: "",
                  modifyInProgress: false,
                  modifySuccess: false,
                  showPermissionsList: false,
                  modifyType: this.props.modifyType,
              };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        if (!this.props.teamId) {
            this.props.history?.replace(paths.currentTeamMembers);
            return;
        }

        this.setState({
            artist: this.props.artist,
        });
    }

    componentDidUpdate(prevProps: StateProps) {
        if (this.props.modifyType !== prevProps.modifyType) {
            this.setState({ modifyType: this.props.modifyType });
        }

        // If we sent an invite, we want to go back to the team screen
        if (this.props.inviteSent && !prevProps.inviteSent) {
            this.props.history?.replace(paths.currentTeamMembers);
        }
    }

    render() {
        const useTeamUi =
            this.props.team.teamType === TeamType.VENDOR ||
            this.props.team.teamType === TeamType.LABEL;

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId:
                            stringIds.ManageCurrentTeam.inviteMemberHeader,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideArtistImgDropdown={true}
                    hideDatePicker={true}
                />
                {useTeamUi && (
                    <Row>
                        <Col>
                            <p
                                style={{
                                    ...labelStyle,
                                    marginTop: rootStyles.spacers.small,
                                }}
                                id={`${testIDPrefix}_TeamNameLabel`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation.team,
                                })}
                            </p>
                            <p
                                style={{
                                    ...rootStyles.textStyles.primary,
                                    marginTop: rootStyles.spacers.small,
                                }}
                                id={`${testIDPrefix}_TeamNameText`}
                            >
                                {this.props.team.teamName}
                            </p>
                        </Col>
                    </Row>
                )}
                <Row style={rowStyle}>
                    <Col
                        sm={6}
                        xs={12}
                        style={{ marginTop: rootStyles.spacers.large }}
                    >
                        <p
                            style={{
                                ...labelStyle,
                                marginTop: rootStyles.spacers.small,
                            }}
                            id={`${testIDPrefix}_FullNameLabel`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId: stringIds.ProvideYourInformation.name,
                            })}
                        </p>
                        <TextField
                            label={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                                stringId:
                                    stringIds.ManageCurrentTeam.fullNameLabel,
                            })}
                            value={this.state.name}
                            onChange={(value: string) =>
                                this.setState({ name: value }, () =>
                                    this.storeState()
                                )
                            }
                            maxLength={GENERIC_TEXT_FIELD_LIMIT}
                            id={testIDPrefix + "_FullNameTextField"}
                            defaultValue={this.state.name}
                        />
                    </Col>
                    <Col
                        sm={6}
                        xs={12}
                        style={{ marginTop: rootStyles.spacers.large }}
                    >
                        <p
                            style={{
                                ...labelStyle,
                                marginTop: rootStyles.spacers.small,
                            }}
                            id={`${testIDPrefix}_EmailLabel`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation.email,
                            })}
                        </p>
                        <TextField
                            label={getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation.email,
                            })}
                            onChange={(value: string) =>
                                this.setState({ email: value }, () =>
                                    this.storeState()
                                )
                            }
                            maxLength={GENERIC_TEXT_FIELD_LIMIT}
                            id={testIDPrefix + "_EmailTextField"}
                            defaultValue={this.state.email}
                        />
                    </Col>
                    <Col
                        sm={6}
                        xs={12}
                        style={{
                            marginTop: rootStyles.spacers.large,
                            marginBottom: rootStyles.spacers.large,
                        }}
                    >
                        <p
                            style={{
                                ...labelStyle,
                                marginTop: rootStyles.spacers.small,
                            }}
                            id={`${testIDPrefix}_CompanyLabel`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .companyLiteral,
                            })}
                        </p>
                        <TextField
                            label={getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .companyOptional,
                            })}
                            value={this.state.company}
                            onChange={(value: string) =>
                                this.setState({ company: value }, () =>
                                    this.storeState()
                                )
                            }
                            maxLength={GENERIC_TEXT_FIELD_LIMIT}
                            id={testIDPrefix + "_CompanyTextField"}
                            defaultValue={this.state.company}
                        />
                    </Col>
                    <Col
                        sm={6}
                        xs={12}
                        style={{
                            marginTop: rootStyles.spacers.large,
                            marginBottom: rootStyles.spacers.large,
                        }}
                    >
                        <p
                            style={{
                                ...labelStyle,
                                marginTop: rootStyles.spacers.small,
                            }}
                            id={`${testIDPrefix}_RoleLabel`}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .roleLiteral,
                            })}
                        </p>
                        <Dropdown
                            style={{
                                ...dropDownStyles.dropdown,
                                maxWidth: 200,
                            }}
                        >
                            <Dropdown.Toggle
                                id="jobPicker"
                                style={{
                                    ...dropDownStyles.dropdownButton,
                                    minWidth: 200,
                                }}
                            >
                                {translateJobTitleToString(
                                    this.state.jobTitle,
                                    this.props.bundleMap
                                )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={dropDownStyles.dropdownMenu}>
                                {this.getJobTitles()}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row
                    style={{ ...rowStyle, marginTop: rootStyles.spacers.large }}
                >
                    <Col>
                        <RadioList
                            elements={this.getRadioItems()}
                            label={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                                stringId:
                                    stringIds.ManageCurrentTeam
                                        .assignAccessLevel,
                            })}
                            initialSelectedIndex={
                                this.state.selectedAccessIndex
                            }
                            onChangeElement={this.onChangeTeamRole}
                            id={testIDPrefix + "_RoleRadioInput"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={warningStyle}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                                stringId:
                                    stringIds.ManageCurrentTeam
                                        .invitationWarning,
                            })}
                        </p>
                    </Col>
                </Row>
                <Row style={{ ...rowStyle, marginTop: 0 }}>
                    <Col xs={12}>
                        {this.userHasPermission(TeamPermissions.SendInvite) && (
                            <LargeSolidButton
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.MANAGECURRENTTEAM_STRINGS,
                                        stringId:
                                            stringIds.ManageCurrentTeam
                                                .sendInvite,
                                    }
                                )}
                                containerStyle={submitButtonStyle}
                                disabled={
                                    this.state.name === undefined ||
                                    this.state.name === "" ||
                                    this.state.email === undefined ||
                                    this.state.email === "" ||
                                    !emailIsValid(this.state.email) ||
                                    this.state.jobTitle === undefined ||
                                    this.state.selectedAccessIndex ===
                                        undefined ||
                                    this.state.modifyInProgress
                                }
                                onClick={this.pressSubmitInvite}
                                id={testIDPrefix + "_SubmitInviteButton"}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }

    private getJobTitles = () => {
        return this.jobTitles.map((item: JobTitle) => {
            return (
                <Dropdown.Item
                    key={translateJobTitleToString(item, this.props.bundleMap)}
                    eventKey={translateJobTitleToString(
                        item,
                        this.props.bundleMap
                    )}
                    onSelect={() => this.onJobTitleChangeCallback(item)}
                    style={dropDownStyles.dropdownItem}
                >
                    <Row>
                        <Col>
                            {translateJobTitleToString(
                                item,
                                this.props.bundleMap
                            )}
                        </Col>
                    </Row>
                </Dropdown.Item>
            );
        });
    };

    private onJobTitleChangeCallback = (item: JobTitle) => {
        this.setState({ jobTitle: item }, () => this.storeState());
    };

    private onChangeTeamRole = (itemIndex: number) => {
        this.setState(
            {
                selectedAccessIndex: itemIndex,
                showPermissionsList: itemIndex === 2,
            },
            () => this.storeState()
        );
    };

    private getRadioItems = () => {
        return [
            this.getAccessLevelInfo(TeamRole.Admin),
            this.getAccessLevelInfo(TeamRole.Viewer),
        ];
    };

    private getAccessLevelInfo(accessLevel: TeamRole) {
        switch (accessLevel) {
            case TeamRole.Owner:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleOwner,
                    }),
                    secondary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId: stringIds.ManageCurrentTeam.adminDescription,
                    }),
                };
            case TeamRole.Admin:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleAdmin,
                    }),
                    secondary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId: stringIds.ManageCurrentTeam.adminDescription,
                    }),
                };
            case TeamRole.Viewer:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleViewer,
                    }),
                    secondary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId: stringIds.ManageCurrentTeam.dataDescription,
                    }),
                };
            case TeamRole.Custom:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleCustom,
                    }),
                };
        }
    }

    private pressSubmitInvite = () => {
        if (!this.props.signedIn) {
            return;
        }

        if (
            this.isViewingInvite() &&
            (!this.state.selectedMember || !this.state.selectedMember.invite)
        ) {
            return;
        }

        localStorage.removeItem(this.getInviteId());

        this.props.submitInvite({
            teamId: this.props.teamId,
            name: this.state.name,
            email: (this.isViewingInvite() && this.state.selectedMember?.invite
                ? this.state.selectedMember.invite.email
                : this.state.email
            ).trim(),
            jobTitle: this.state.jobTitle,
            company: this.state.company,
            access: this.translateAccessIndexToTeamRole(
                this.state.selectedAccessIndex !== undefined
                    ? this.state.selectedAccessIndex
                    : 1
            ),
            permissions: [],
            requestPath: paths.inviteTeamMember,
            isResend: false,
        });
    };

    private translateAccessIndexToTeamRole(index: number): TeamRole {
        if (index === AdminAccessIndex) {
            return TeamRole.Admin;
        } else {
            return TeamRole.Viewer;
        }
    }

    private isViewingInvite() {
        return (
            this.state.selectedMember?.invite &&
            this.state.modifyType !== ModifyMemberType.Add
        );
    }

    private userHasPermission(teamPermission: TeamPermissions) {
        return (
            this.props.userPermissions &&
            this.props.userPermissions.includes(teamPermission)
        );
    }

    private getInviteId = () => {
        return `${INVITE_STATE}_${this.props.teamId}`;
    };

    private storeState = () => {
        localStorage.setItem(this.getInviteId(), JSON.stringify(this.state));
    };
}
const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.label,
    textTransform: "uppercase",
    color: rootStyles.glassColors.primary4,
};
const submitButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: 250,
    margin: rootStyles.spacers.small,
};
const rowStyle: React.CSSProperties = {
    alignItems: "center",
};

const warningStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    margin: rootStyles.spacers.small,
    color: rootStyles.colors.primary,
};

function mapStateToProps(state: RootState) {
    return {
        ...inviteTeamMembersScreenSelector(state),
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        submitInvite: (payload: inviteTeamMemberPayload) =>
            dispatch(teamManagementActions.inviteTeamMember(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(InviteTeamMemberScreen)
);
