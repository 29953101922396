import { RouteChildrenProps } from "react-router";
import { MessageBundle } from "@amzn/arb-tools";
import {
    queryResult,
    songPerformanceResult,
    albumPerformanceResult,
    playlistPerformanceResult,
    stationPerformanceResult,
} from "./responses/reportingResponses";
import {
    catalogItem,
    artist,
    recentlyAddedToPlaylistTrack,
    albumRelease,
} from "./media";
import {
    ErrorPayload,
    overviewRequestPayload,
} from "./payloads";

export enum GranularityType {
    Fifteen_Minute = "FIFTEEN_MINUTE",
    Hour = "HOUR",
    Day = "DAY",
    Week = "WEEK",
    Month = "MONTH",
    All = "ALL",
}

export enum SalesType {
    Physical = "PHYSICAL",
    Digital = "DIGITAL",
    All = "ALL",
}

export enum FanTiersType {
    Fan = "FAN",
    SuperFan = "SUPER_FAN",
}

export enum ArtistProfileIssueType {
    IncorrectContent = "PROFILE_HAS_INCORRECT_CONTENT",
    ContentOnDifferentArtist = "CONTENT_ON_ANOTHER_PROFILE",
}

export enum EntityType {
    ARTIST = "ARTIST",
    Album = "ALBUM",
    Playlist = "PLAYLIST",
    Station = "STATION",
    Track = "TRACK",
    Interlude = "INTERLUDE",
    Merch = "MERCH",
}

export enum ContentType {
    Track = "TRACK",
    Album = "ALBUM",
}

// This is used by the TimeRangePicker. Will need to get converted into start/end dates in the sagas.
export enum TimeRange {
    Today = "today",
    AllTime = "allTime",
    SevenDays = "sevenDays",
    TwentyEightDays = "twentyEightDays",
    Custom = "custom",
}

export enum PitchStatus {
    Pitched = "PITCHED",
    Pitchable = "PITCHABLE",
    Unpitchable = "UNPITCHABLE",
}

export enum ProfileImageSize {
    ExtraSmall = 60,
    Small = 100,
    Medium = 144,
    Large = 200,
}

export enum ChartType {
    StreamsOverview,
    ListenersOverview,
    TopSongs,
    TopAlbums,
    StreamsBySources,
    TotalListeners,
    AgeRanges,
    Gender,
    FeaturedPlaylists,
    FeaturedStations,
    VoiceIndex,
    VoiceRequests,
    SongVoiceRequests,
    StreamingDetails,
    PhysicalSalesActivity,
    PhysicalSales,
    PhysicalPreOrders,
    DigitalSalesActivity,
    DigitalSales,
    DigitalPreOrders,
}

export enum QueryType {
    Artists = "ARTISTS",
    Playlists = "PLAYLISTS",
    Albums = "ALBUMS",
    Stations = "STATIONS",
    Tracks = "TRACKS",
    TrackTitleSets = "TRACK_TITLESETS",
    AlbumTitleSets = "ALBUM_TITLESETS",
    TitleSets = "TITLESETS",
}

export enum CatalogItemType {
    Artists = "ARTISTS",
    Playlists = "PLAYLISTS",
    Albums = "ALBUMS",
    Stations = "STATIONS",
    Tracks = "TRACKS",
}

export enum StreamSourceType {
    Browse = "Browse",
    Library = "Library",
    Playlist = "Playlist",
    Station = "Station",
}

export enum AlexaStreamingType {
    Artist = "ARTIST",
    Album = "ALBUM",
    Track = "TRACK",
    Lyrics = "LYRICS",
}

export enum SongScreenViewMode {
    voice = "VOICE",
    album = "ALBUM",
    playlist = "PLAYLIST",
    station = "STATION",
    songs = "SONG",
}

export enum PartnerName {
    facebook = "facebook",
    twitter = "twitter",
    instagramV3 = "instagramV3",
    cdBaby = "cdbaby",
    distrokid = "distrokid",
    tunecore = "tunecore",
    tunecoreJP = "tunecorejapan",
}

export enum localStorageStrings {
    inviteId = "inviteId",
    notAllowlisted = "notAllowlisted",
    csrf = "csrf",
    redirectPath = "redirectPath",
    deeplinkUrl = "deeplinkUrl",
}

export enum cookieStrings {
    userId = "userId",
    // Unique Browser IDs - https://w.amazon.com/index.php/IdentityServices/AuthenticationPortal/Cookies#Example_cookie_names_by_region
    ubidUS = "ubid-main",
    ubidJP = "ubid-acbjp",
    sessionId = "session-id",
    sessionToken = "session-token",
    xUS = "x-main",
    xJP = "x-acbjp",
}

export enum ImageType {
    ProfileImage = "ARTIST_PROFILE_IMAGE",
    BackgroundImage = "ARTIST_BACKGROUND_IMAGE",
}

export const host = {
    hostName: "web.artists.amazon.com",
};

export type RequiredReportingProps = {
    selectedRange: TimeRange;
    startDate?: Date;
    endDate?: Date;
    catalog: Map<string, catalogItem>;
    signedIn: boolean;
    teamId?: string;
    selectedArtist?: string;
    inProgress?: boolean;
    error?: ErrorPayload;
    hydratingAsins: string[];
    failedAsins: string[];
    catalogBuildCompleted: boolean;
    hydrationInProgress: boolean;
    artist?: artist;
    recentlyAddedToPlaylistData: recentlyAddedToPlaylistTrack[];
    albumReleases?: albumRelease[];
    userLocale?: string;
};

export type RequiredReportingState = {
    refreshing: boolean;
    showError: boolean;
    finishedInitialLoad?: boolean;
    loadedReports?: boolean;
    viewRef?: any;
};

export type RequiredReportingDispatchProps = {
    getReport: (payload: overviewRequestPayload) => void;
    clearFailedAsins: () => void;
};

export type CatalogProps = {
    catalog: Map<string, catalogItem>;
    hydratingAsins: string[];
    failedAsins: string[];
    catalogBuildCompleted: boolean;
    hydrationInProgress: boolean;
};

export type TableRowProps = {
    index?: string;
    image?: string;
    fallbackImage?: any;
    imageDescription?: string;
    primary?: string;
    secondary?: string;
    tertiary?: string;
    stat1?: string | JSX.Element;
    stat2?: string | JSX.Element;
    displayNewBadge?: boolean;
    onSelect?: () => void;
    id: string;
    isLoading?: boolean;
    showBottomBorder?: boolean;
    additionalRowStyle?: React.CSSProperties;
    date?: string;
};

export type itemProps = {
    code: string;
    name: string;
    hide?: boolean;
    selected?: boolean;
    image?: string;
    fallbackImage?: string;
};

export type group = {
    properties: itemProps;
    items: itemProps[];
};

export type dimension = {
    x: number;
    y: number;
    z?: number;
};

export type reportData = Map<string, queryResult>;
export type songPerformanceData = Map<string, songPerformanceResult>;
export type albumPerformanceData = Map<string, albumPerformanceResult>;
export type playlistPerformanceData = Map<string, playlistPerformanceResult>;
export type stationPerformanceData = Map<string, stationPerformanceResult>;
export type inProgressData = Map<string, boolean>;
export type Bundle = MessageBundle;
export type BundleMap = Map<string, Bundle>;

export type NavigationProps = RouteChildrenProps & {};

export enum CardLayout {
    Portrait = "PORTRAIT",
    Landscape = "LANDSCAPE",
    Square = "SQUARE",
}

export enum PromoCardBackgroundColor {
    CYAN = "Cyan",
    CYAN_PURPLE = "Cyan-Purple",
    PURPLE = "Purple",
    RED_PURPLE = "Red-Purple",
    VIOLET_CHARCOAL = "Violet-Charcoal",
    YELLOW = "Yellow",
    CHARCOAL = "Charcoal",
}
