import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import { Table, Loading, InlineError, CommonHeader } from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingState,
    RequiredReportingDispatchProps,
    TableRowProps,
    queryRequest,
    TimeRange,
    timeRangePayload,
    countryInfo,
    reportingOverviewRequestPayload,
    overviewRequestPayload,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    countriesScreenSelector,
    reportingActions,
    userActions,
} from "../../../store";
import {
    getLocalizedString,
    paths,
    generateCountryRows,
    DEMOGRAPHICS_ITEM_COUNT,
    guessTimeZone,
    generateQueryWithoutGranularity,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";

const testIDPrefix = "CountriesScreen";
const metricPrefix = "countriesPage";

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    topCountries: countryInfo[];
    bundleMap: BundleMap;
};
type DispatchProps = RequiredReportingDispatchProps & {
    getReports: (payload: reportingOverviewRequestPayload) => void;
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState;

class CountriesScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });

        this.getReports();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports(true);
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports(true);
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.state.loadedReports &&
            this.props.catalogBuildCompleted &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }
    }

    render() {
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }
        const topCountriesHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.countriesTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.listenersLabel,
            }),
        ];

        const topCountries: TableRowProps[] = generateCountryRows(
            this.props.topCountries,
            testIDPrefix + "_TopCountriesRows"
        );

        const topCountriesRows = topCountries.slice(0, DEMOGRAPHICS_ITEM_COUNT);

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    ...rootStyles.containerStyles.tableContainerStyle,
                }}
            >
                {/* {this.props.artist &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.artist?.images?.artSmall }}
                    />
                } */}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.topCountriesTitle,
                    })}
                    id={`${testIDPrefix}_Header`}
                />
                <Row>
                    <Col>
                        <Table
                            rows={topCountriesRows}
                            labels={topCountriesHeader}
                            id={testIDPrefix + "_CountriesTable"}
                            showIndex={true}
                            footer={
                                <div
                                    style={{ height: rootStyles.spacers.huge }}
                                />
                            }
                            emptyMessage={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                    stringId: stringIds.EmptyState.message,
                                }
                            )}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    private getReports = (isRefresh?: boolean) => {
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
        };

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const requestId = this.getRequestId();
        query.topCountriesForArtistListenersQuery = {
            ...generateQueryWithoutGranularity(
                timeRangePayload,
                guessTimeZone()
            ),
        };

        // Dispatch request for overview
        const payload: overviewRequestPayload = {
            query: query,
            requestPath: paths.countries,
            requestId: requestId,
            isRefresh: isRefresh,
            teamId: this.props.teamId,
        };

        this.props.getReport(payload);
    };

    private getRequestId() {
        if (this.props.selectedRange === TimeRange.Custom) {
            return `seeAll_topCountries_${this.props.startDate?.toString()}_${this.props.endDate?.toString()}`;
        }
        return `seeAll_topCountries_${this.props.selectedRange.toString()}`;
    }
}

function mapStateToProps(state: RootState) {
    return countriesScreenSelector(state, paths.countries);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReport: (payload: overviewRequestPayload) =>
            dispatch(reportingActions.getReports(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CountriesScreen)
);
