import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import {
    artistImageActions,
    errorActions,
    opsMetricsActions,
} from "../actions";
import { uploadImage, uploadCASImage } from "../../service";
import {
    METRIC_KEYS,
    uploadResults,
    artistImagePayload,
    ErrorPayload,
} from "../../models";
import { createSuccessOpsMetricsPayload } from "../../utils";
import { AxiosResponse } from "axios";

export const artistImageSagas = [watchArtistImage()];

function* uploadArtistImage(action: Action<artistImagePayload>) {
    const start = Date.now();
    const functionName = "uploadArtistImage";
    try {
        console.log("Uploading artist image: " + action.payload.imageType);
        yield put(artistImageActions.artistImageUploadInProgress(true));
        yield put(artistImageActions.artistImageUploadComplete(false));

        // make the service call to get the uploadUrl
        const response: uploadResults = yield uploadImage(action.payload);
        // when we have a result, dispatch the completed task
        const uploadResponse: AxiosResponse = yield uploadCASImage(
            action.payload.imageFile,
            response.signedUrl
        );

        console.log(
            "Got result for " +
                action.payload +
                " in " +
                `${Date.now() - start} ms`
        );

        yield put(artistImageActions.artistImageUploadComplete(true));
        yield put(artistImageActions.artistImageUploadInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.artistAsin, `${action.payload.asin}`],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        const payload: ErrorPayload = {
            requestPath: action.payload.requestPath,
            exception: ex,
            dataPoints: dataPoints,
            eventName: functionName,
        };

        yield put(errorActions.handleError(payload));
        yield put(artistImageActions.artistImageUploadInProgress(false));
    }
}

function* watchArtistImage() {
    yield takeEvery(artistImageActions.artistUpload.type, uploadArtistImage);
}
