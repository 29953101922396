import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {} from "@amzn/arb-tools";
import _ from "lodash";
import { CountriesStreamsProps, CountriesStreams } from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingState,
    RequiredReportingDispatchProps,
    METRIC_KEYS,
    queryRequest,
    TimeRange,
    countryInfo,
    reportingOverviewRequestPayload,
    overviewRequestPayload,
    timeRangePayload,
    EntityType,
    station,
    BundleMap,
} from "../../../models";
import {
    RootState,
    reportingActions,
    userActions,
    stationCountriesScreenSelector,
} from "../../../store";
import {
    paths,
    guessTimeZone,
    generateQueryWithoutGranularity,
    getLocalizedString,
    getSeeAllCountriesRequestId,
} from "../../../utils";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";

const testIDPrefix = "StationCountriesScreen";
const metricPrefix = "stationCountriesPage";

type ViewProps = {
    asin: string;
};

type StateProps = RequiredReportingProps & {
    topCountries: countryInfo[];
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    getReports: (payload: reportingOverviewRequestPayload) => void;
    updateCurrentPath: (payload: string) => void;
    selectAsin: (asin: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    stationCatalogItem?: station;
};

class StationCountriesScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }
        window.scrollTo(0, 0);

        this.props.selectAsin(asin);

        this.setState({ finishedInitialLoad: false });

        const stationCatalogItem = this.props.catalog.get(asin) as station;
        if (stationCatalogItem) {
            this.setState({ stationCatalogItem });
        }

        this.getReports();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports(true);
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.state.loadedReports &&
            this.props.catalogBuildCompleted &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }
    }

    render() {
        const countriesStreamsProps: CountriesStreamsProps = {
            topCountries: this.props.topCountries,
            testIDPrefix: testIDPrefix,
            inProgress: this.props.inProgress,
            refreshing: this.state.refreshing,
            showError: this.state.showError,
            catalogItem: this.state.stationCatalogItem,
            entityLabel: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.stationsLabel,
            }),
            getReports: this.getReports,
        };

        return <CountriesStreams {...countriesStreamsProps} />;
    }

    private getReports = (isRefresh?: boolean) => {
        const asin = this.props.match.params.asin;
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId ||
            !asin
        ) {
            return;
        }

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
        };

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const requestId = getSeeAllCountriesRequestId(
            "station",
            asin,
            timeRangePayload
        );
        query.topCountriesForEntityStreamsQuery = {
            ...generateQueryWithoutGranularity(
                timeRangePayload,
                guessTimeZone()
            ),
            entityType: EntityType.Station,
            entityId: asin,
        };

        // Dispatch request
        const payload: overviewRequestPayload = {
            query: query,
            requestPath: paths.stationCountries,
            requestId: requestId,
            isRefresh: isRefresh,
            teamId: this.props.teamId,
        };

        this.props.getReport(payload);
    };
}

function mapStateToProps(state: RootState) {
    return stationCountriesScreenSelector(state, paths.stationCountries);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReport: (payload: overviewRequestPayload) =>
            dispatch(reportingActions.getReports(payload)),
        selectAsin: (asin: string) =>
            dispatch(reportingActions.setSelectedStationAsin(asin)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(StationCountriesScreen)
);
