import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import _ from "lodash";
import {
    errorActions,
    userActions,
    outageStatusActions,
    opsMetricsActions,
} from "../actions";
import { metricDataPoints, METRIC_KEYS, ErrorPayload } from "../../models";
import { createErrorOpsMetricsPayload, paths } from "../../utils";
import { reactAppEnv } from "../../service/common";

export const errorSagas = [watchHandleError()];

function* handleError(action: Action<ErrorPayload>) {
    try {
        console.log("Handling error " + action.payload.eventName);
        const errorPayload: ErrorPayload = action.payload;
        const dataPoints: metricDataPoints = errorPayload.dataPoints
            ? errorPayload.dataPoints
            : new Map<string, string | undefined>();

        dataPoints.set(METRIC_KEYS.page, errorPayload.requestPath);

        if (!errorPayload.silent) {
            yield put(errorActions.displayError(errorPayload));
        }

        if (errorPayload.uncaught && errorPayload.error) {
            dataPoints.set(METRIC_KEYS.errorName, errorPayload.error.name);
            dataPoints.set(
                METRIC_KEYS.errorMessage,
                errorPayload.error.message
            );
        }

        const exception = errorPayload.exception;

        // Check if error payload has an exception
        if (exception && exception.response) {
            dataPoints.set(METRIC_KEYS.errorCode, exception.response.status);
            dataPoints.set(
                METRIC_KEYS.errorMessage,
                exception.response.message
            );

            if (
                exception.response.status === 401 ||
                exception.response.status === 403
            ) {
                console.log(`Received ${exception.response.status}`);
                if (reactAppEnv !== "local") {
                    console.log(
                        "Bad request. But also if token has expired. Refreshing token and retrying."
                    );
                    // Opt out flow doesn't require auth token. 400/403 from opt out indicate the directId is not valid
                    // Skip refresh token to avoid being redirected to sign in page from opt out
                    if (action.payload.requestPath !== paths.optOut) {
                        yield put(userActions.shouldRefreshToken(true));
                    }

                    // Retry the action if needed with a second delay
                    if (errorPayload.shouldRetry && errorPayload.retryAction) {
                        console.log("Retrying action");
                        _.delay(yield put(errorPayload.retryAction), 1000);
                    }
                }
            }

            // Error codes for checking the outage banner status
            if (
                !action.payload.dontRefreshOutageStatus &&
                (exception.response.status === 500 ||
                    exception.response.status === 503 ||
                    exception.response.status === 504)
            ) {
                yield put(outageStatusActions.getOutageStatus());
            }

            // Handle 504s. These are typically cold starts, so we can retry if retry action is passed in
            if (exception.response.status === 504) {
                console.log("Cold start");

                // Retry the action if needed
                if (errorPayload.shouldRetry && errorPayload.retryAction) {
                    console.log("Retrying action");
                    yield put(errorPayload.retryAction);
                }
            }
        }
        
        let stackTrace: string | undefined;
        if (exception instanceof Error) {
            stackTrace = `${exception.message} - ${exception.stack}`;
        } else if (typeof exception === "string") {
            stackTrace = exception;
        }
        yield put(
            opsMetricsActions.batchMetric(
                createErrorOpsMetricsPayload(
                    action.payload.eventName || "unknownError",
                    stackTrace,
                    action.payload.dataPoints
                )
            )
        );
    } catch (ex) {
        console.log("Could not handle error " + ex);
        // SIM https://jira.music.amazon.dev/browse/AEM-536
    }
}

function* watchHandleError() {
    yield takeEvery(errorActions.handleError.type, handleError);
}
