import _ from "lodash";
import * as React from "react";
import {
    RootState,
    artistSearchActions,
    deeplinkResolverSelector,
    oAuthActions,
    userActions,
    clientMetricsActions,
    opsMetricsActions,
} from "../../store";
import { AnyAction, Dispatch } from "redux";
import {
    TeamType,
    artist,
    artistSearchQueryPayload,
    selectArtistPayload,
    teamInfo,
    deeplinkMap,
    clientMetricsPayload,
    BatchOpsMetricsPayload,
    METRIC_KEYS,
    EntityType,
    FeaturePermission,
    Deeplinks,
    deeplinkConfig,
} from "../../models";
import {
    SelectArtistProps,
    buildDeepLink,
    buildUIClickPayload,
    createSuccessOpsMetricsPayload,
    isValidArtistAsin,
    onArtistSelected,
    paths,
    validateArtist,
    createErrorOpsMetricsPayload,
} from "../../utils";
import { connect } from "react-redux";
import { Loading } from "../components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { deeplinks } from "@amzn/ziggy-asset";
import { clearDeeplinkSelectedArtist } from "../../store/actions/userActions";

const metricPrefix = "deeplinkResolver";

type State = {
    hasSelectedArtist: boolean;
    hasSearchedArtist: boolean;
};

type ViewProps = {};

type DispatchProps = {
    registerUser: () => void;
    getCustomerSupportData: () => void;
    updateDeeplinkRoute: (payload: string) => void;
    clearArtistSearch: () => void;
    artistSelect: (payload: selectArtistPayload) => void;
    updateDeeplinkMapConfig: (payload: deeplinkConfig) => void;
    resetDeeplink: () => void;
    artistSearch: (payload: artistSearchQueryPayload) => void;
    updateIsDeeplinkArtistSearchInProgress: (payload: boolean) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    batchMetric: (payload: BatchOpsMetricsPayload) => void;
    clearDeeplinkSelectedArtist: () => void;
};

type StateProps = {
    deeplinkRoute?: string;
    isPrivileged?: boolean;
    teams?: teamInfo[];
    signedIn: boolean;
    isDeeplinkArtistSearchInProgress?: boolean;
    artists: artist[];
    isGetFeaturePermissionInProgress?: boolean;
    featurePermissionsList: FeaturePermission[];
    deeplinkMap?: deeplinkConfig;
    selectedArtistAsin?: string;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

export class DeeplinkResolverScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasSelectedArtist: false,
            hasSearchedArtist: false,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    componentDidUpdate(prevProps: Props, prevState: State) {
        const url = new URL(document.URL);
        const deepLinkAttributes = buildDeepLink(url);
        let deeplinkPath = deeplinkMap.get(deepLinkAttributes.action);
        if (!this.props.deeplinkRoute) {
            this.props.updateDeeplinkRoute(url.pathname + url.search);
            this.props.batchMetric(
                createSuccessOpsMetricsPayload(
                    "DeeplinkClicked: " + document.URL
                )
            );
            this.props.clearDeeplinkSelectedArtist();
            this.props.registerUser();
            this.props.getCustomerSupportData();
            const deeplinkAction = deepLinkAttributes.action
                ? deeplinks[deepLinkAttributes.action as keyof typeof deeplinks]
                : deeplinks.noAction;
            this.props.sendClientMetrics(
                buildUIClickPayload(
                    deeplinkAction,
                    undefined,
                    deepLinkAttributes.artistAsin,
                    EntityType.ARTIST
                )
            );
            // logging metrics if the action is not provided
            if (deepLinkAttributes.action === undefined) {
                const dataPoints = new Map<string, string | undefined>([
                    [METRIC_KEYS.deeplink, METRIC_KEYS.undefinedAction],
                ]);
                this.props.batchMetric(
                    createErrorOpsMetricsPayload(
                        "resolveDeeplinkAction",
                        undefined,
                        dataPoints
                    )
                );
            }
            // logging metrics if the action provided is not in scope for deeplinks
            if (
                deepLinkAttributes.action !== undefined &&
                deeplinkMap.get(deepLinkAttributes.action) === undefined
            ) {
                const dataPoints = new Map<string, string | undefined>([
                    [METRIC_KEYS.deeplink, METRIC_KEYS.invalidAction],
                ]);
                this.props.batchMetric(
                    createErrorOpsMetricsPayload(
                        "resolveDeeplinkAction",
                        undefined,
                        dataPoints
                    )
                );
            }
            //if non-artist related action then go to the action
            if (deeplinkPath !== undefined && !deeplinkPath.artistRelated) {
                this.props.batchMetric(
                    createSuccessOpsMetricsPayload(
                        deeplinks[deeplinkPath.path as keyof typeof deeplinks]
                    )
                );
                this.props.resetDeeplink();
                this.props.history.replace(deeplinkPath.path);
                return;
            }
            // if both artistAsin and action are undefined then go to artist select page
            if (
                deeplinkPath === undefined &&
                deepLinkAttributes.artistAsin === undefined
            ) {
                this.props.history.replace(paths.artistSelect);
                return;
            }
            // if no teams then path is on requesterType
            if (!this.props.teams?.length) {
                // if the action is provided then it should be considered invalid if the user has no teams
                if (deeplinkPath !== undefined) {
                    const dataPoints = new Map<string, string | undefined>([
                        [METRIC_KEYS.deeplink, METRIC_KEYS.invalidAction],
                    ]);
                    this.props.batchMetric(
                        createErrorOpsMetricsPayload(
                            "resolveDeeplinkAction",
                            undefined,
                            dataPoints
                        )
                    );
                }
                this.props.resetDeeplink();
                this.props.batchMetric(
                    createSuccessOpsMetricsPayload(
                        "Deeplink ReRouting to fallback: " + paths.requesterType
                    )
                );
                this.props.history.replace(paths.requesterType);
                return;
            }
            // check if artist asin is undefined and meets the regex
            if (
                deepLinkAttributes.artistAsin !== undefined &&
                isValidArtistAsin(deepLinkAttributes.artistAsin)
            ) {
                // if artistAsin is present
                const deeplinkArtist: artist = {
                    asin: deepLinkAttributes.artistAsin,
                };

                const team = validateArtist(
                    this.props.teams,
                    deepLinkAttributes.artistAsin
                );
                // if validateArtist
                if (team !== undefined) {
                    // check if validateArtist returns team type for vendor or label to perform artist search
                    if (team === TeamType.LABEL || team === TeamType.VENDOR) {
                        this.props.updateIsDeeplinkArtistSearchInProgress(true);
                    } else {
                        this.props.updateDeeplinkMapConfig(
                            deeplinkPath
                                ? deeplinkPath
                                : (deeplinkMap.get(
                                      Deeplinks.Reports
                                  ) as deeplinkConfig)
                        );
                        if (!this.state.hasSelectedArtist) {
                            this.setState({
                                hasSelectedArtist: true,
                            });
                            this.artistSelected(
                                deeplinkArtist,
                                deeplinkPath
                                    ? url.pathname + url.search
                                    : paths.reports
                            );
                        }
                    }
                } else {
                    // invalid artist so go to select
                    const dataPoints = new Map<string, string | undefined>([
                        [METRIC_KEYS.deeplink, METRIC_KEYS.invalidArtistAsin],
                    ]);
                    this.props.batchMetric(
                        createErrorOpsMetricsPayload(
                            "resolveDeeplinkAction",
                            undefined,
                            dataPoints
                        )
                    );
                    this.props.batchMetric(
                        createSuccessOpsMetricsPayload(
                            "Deeplink ReRouting to fallback: " +
                                paths.artistSelect
                        )
                    );
                    this.props.history.replace(paths.artistSelect);
                    return;
                }
            } else {
                // undefined artist so go to select and update deeplink path to continue deeplinking after selecting an artist
                const dataPoints = new Map<string, string | undefined>([
                    [METRIC_KEYS.deeplink, METRIC_KEYS.undefinedArtistAsin],
                ]);
                this.props.batchMetric(
                    createErrorOpsMetricsPayload(
                        "resolveDeeplinkAction",
                        undefined,
                        dataPoints
                    )
                );
                this.props.updateDeeplinkMapConfig(
                    deeplinkPath
                        ? deeplinkPath
                        : (deeplinkMap.get(
                              Deeplinks.ArtistSelect
                          ) as deeplinkConfig)
                );
                this.props.batchMetric(
                    createSuccessOpsMetricsPayload(
                        "Deeplink ReRouting to fallback: " + paths.artistSelect
                    )
                );
                this.props.history.replace(paths.artistSelect);
            }
        } else if (
            this.props.isDeeplinkArtistSearchInProgress &&
            deepLinkAttributes.artistAsin !== undefined
        ) {
            const payload: artistSearchQueryPayload = {
                query: deepLinkAttributes.artistAsin,
                requestPath: deeplinkPath
                    ? url.pathname + url.search
                    : paths.artistSelect,
                musicTerritory: "US",
            };
            this.props.artistSearch(payload);
        } else if (
            !this.props.isDeeplinkArtistSearchInProgress &&
            !this.props.selectedArtistAsin
        ) {
            if (this.props.artists.length !== 1) {
                this.props.history.replace(paths.artistSelect);
            }
            const deeplinkArtist: artist = {
                asin: deepLinkAttributes.artistAsin,
            };
            this.props.artists.forEach((artist) => {
                if (
                    artist.asin === deepLinkAttributes.artistAsin &&
                    artist.hasPermission
                ) {
                    if (!this.state.hasSelectedArtist) {
                        this.setState({
                            hasSelectedArtist: true,
                        });
                        this.props.updateDeeplinkMapConfig(
                            deeplinkPath
                                ? deeplinkPath
                                : (deeplinkMap.get(
                                      Deeplinks.Reports
                                  ) as deeplinkConfig)
                        );
                        this.artistSelected(
                            deeplinkArtist,
                            this.props.deeplinkRoute
                                ? this.props.deeplinkRoute
                                : paths.reports
                        );
                    }
                } else {
                    // if not found artist during search then go back to selectArtist and clear the search
                    this.props.clearArtistSearch();
                    this.props.history.replace(paths.artistSelect);
                }
            });
        } else if (
            !this.props.isGetFeaturePermissionInProgress &&
            this.props.featurePermissionsList.length > 0
        ) {
            if (
                deepLinkAttributes.artistAsin !== undefined &&
                this.props.selectedArtistAsin === deepLinkAttributes.artistAsin
            ) {
                this.processFeaturePermissions();
            } else if (
                deepLinkAttributes.artistAsin === undefined &&
                this.props.selectedArtistAsin !== undefined
            ) {
                this.processFeaturePermissions();
            }
        }
    }

    public render() {
        return (
            <div id={"DeeplinkResolving" + "-FullPageLoading"}>
                <Loading />
            </div>
        );
    }

    private processFeaturePermissions() {
        const deeplink = this.props.deeplinkMap;
        if (deeplink !== undefined) {
            if (
                deeplink.hasFeaturePermission &&
                deeplink.featurePermission !== undefined
            ) {
                this.props.featurePermissionsList.forEach((permission) => {
                    deeplink.featurePermission?.forEach((deeplinkFeature) => {
                        if (permission.feature === deeplinkFeature) {
                            this.props.batchMetric(
                                createSuccessOpsMetricsPayload(
                                    "Deeplink Routing to: " + deeplink.path
                                )
                            );
                            this.props.history.replace(deeplink.path);
                            return;
                        }
                    });
                });
            } else {
                // go to deeplink.path
                createSuccessOpsMetricsPayload(
                    "Deeplink Routing to: " + deeplink.path
                );
                this.props.history.replace(deeplink.path);
                return;
            }
        } else {
            // else go to reports
            const dataPoints = new Map<string, string | undefined>([
                [METRIC_KEYS.deeplink, METRIC_KEYS.invalidAction],
            ]);
            this.props.batchMetric(
                createErrorOpsMetricsPayload(
                    "resolveDeeplinkAction",
                    undefined,
                    dataPoints
                )
            );
            createSuccessOpsMetricsPayload(
                "Deeplink ReRouting to fallback: " + paths.reports
            );
            this.props.history.replace(paths.reports);
            return;
        }
        this.props.resetDeeplink();
        return;
    }

    private artistSelected(artist: artist, path: string) {
        let request: SelectArtistProps = {
            signedIn: this.props.signedIn,
            selectedArtist: artist,
            teams: this.props.teams,
            historyProps: this.props,
            path: path,
            metricPrefix: metricPrefix,
            isPrivileged: this.props.isPrivileged,
            artistSelect: this.props.artistSelect,
        };
        let artistSelectReturn = onArtistSelected(request);
        if (artistSelectReturn) {
            // artist selected
        }
    }
}

function mapStateToProps(state: RootState) {
    return {
        ...deeplinkResolverSelector(state, paths.deeplinkResolver),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        registerUser: () => dispatch(userActions.registerUser()),
        getCustomerSupportData: () =>
            dispatch(oAuthActions.getCustomerSupportData()),
        updateDeeplinkRoute: (payload: string) =>
            dispatch(userActions.updateDeeplinkRoute(payload)),
        artistSelect: (payload: selectArtistPayload) =>
            dispatch(userActions.selectArtist(payload)),
        clearArtistSearch: () =>
            dispatch(artistSearchActions.clearArtistSearch()),
        updateDeeplinkMapConfig: (payload: deeplinkConfig) =>
            dispatch(userActions.updateDeeplinkMapConfig(payload)),
        resetDeeplink: () => dispatch(userActions.resetDeeplink()),
        artistSearch: (payload: artistSearchQueryPayload) =>
            dispatch(artistSearchActions.artistSearch(payload)),
        updateIsDeeplinkArtistSearchInProgress: (payload: boolean) =>
            dispatch(
                userActions.updateIsDeeplinkArtistSearchInProgress(payload)
            ),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        batchMetric: (payload: BatchOpsMetricsPayload) =>
            dispatch(opsMetricsActions.batchMetric(payload)),
        clearDeeplinkSelectedArtist: () =>
            dispatch(userActions.clearDeeplinkSelectedArtist()),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeeplinkResolverScreen)
);
