import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import {
    track,
    RequiredReportingState,
    RequiredReportingProps,
    TableRowProps,
    trackInfo,
    programmingInfo,
    streamSourceInfo,
    trackStreamsInfo,
    trackListenersInfo,
    TimeRange,
    songPerformanceRequestPayload,
    timeRangePayload,
    countryInfo,
    EntityType,
    baseMediaItem,
    generateShareLinkPayload,
    shareContentResponse,
    album,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    BundleMap,
} from "../../../models";
import {
    RootState,
    reportingActions,
    songsPerformanceScreenSelector,
    catalogActions,
    userActions,
    artistIssueActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import * as rootStyles from "../../styles";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import {
    LineGraphProps,
    LineGraph,
    SmallGlassButton,
    Table,
    Loading,
    BarChart,
    BarChartProps,
    ProfileHeadProps,
    ProfileHead,
    InlineError,
    VerticalSpacedTable,
    TableSeeAllButton,
    ShareContentMenu,
    DownloadButton,
} from "../../components";
import {
    getLocalizedString,
    generateQuery,
    parseTrackDataPoints,
    timeRangeToTickCount,
    SUMMARY_TABLE_ITEM_COUNT,
    generateQueryWithoutGranularity,
    generatePlaylistRows,
    generateStationRows,
    paths,
    testIDSuffixes,
    formatNumber,
    parseStreamSourceBar,
    formatDatum,
    formatTooltipDatum,
    guessTimeZone,
    generateCountryRows,
    getNewPlaylistAsins,
    buildUIClickPayload,
} from "../../../utils";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";
import { PromoCardStep } from "../../../utils/promoCard/promoCardConstants";

const testIDPrefix = "SongPerformanceScreen";
const metricPrefix = "songPerformancePage";
const pagePath = paths.songPerformance;

type ViewProps = {
    asin: string;
};

type StateProps = RequiredReportingProps & {
    tracks: trackInfo[];
    playlists: programmingInfo[];
    stations: programmingInfo[];
    streamSources: streamSourceInfo[];
    topCountries: countryInfo[];
    trackStreamInfo?: trackStreamsInfo;
    trackListenerInfo?: trackListenersInfo;
    selectedAsin: string;
    shareLinkResponse: shareContentResponse;
    generateShareLinkInProgress: boolean;
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
    showHypeDeck: boolean;
};
type DispatchProps = {
    getSongPerformance: (request: songPerformanceRequestPayload) => void;
    clearFailedAsins: () => void;
    unselectAsin: (asin: string) => void;
    selectAsin: (asin: string) => void;
    updateCurrentPath: (payload: string) => void;
    selectReportIssueItem: (payload: baseMediaItem) => void;
    generateShareLink: (payload: generateShareLinkPayload) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type State = RequiredReportingState & {
    trackCatalogItem?: track;
    albumCatalogItem?: album;
    showShareContentMenu: boolean;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

class SongPerformanceScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            showShareContentMenu: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }

        this.setState({ finishedInitialLoad: false });

        this.props.selectAsin(asin);

        const trackCatalogItem = this.props.catalog.get(asin) as track;

        if (trackCatalogItem) {
            this.setState({ trackCatalogItem: trackCatalogItem });

            const albumCatalogItem = this.props.catalog.get(
                trackCatalogItem.albumAsin
            ) as album;
            if (albumCatalogItem) {
                this.setState({ albumCatalogItem: albumCatalogItem });
            }
        }

        // When mounted, issue a query to get the data for a song with a specific track asin and time range
        this.getSongPerformance();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedRange !== this.props.selectedRange) {
            this.getSongPerformance();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getSongPerformance();
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            if (this.props.error && this.props.error.requestPath === pagePath) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const streamCount =
            (this.props.trackStreamInfo &&
                this.props.trackStreamInfo.totalCount) ||
            0;
        // const timeRangeString = getDatePickerString(this.props.selectedRange);
        const totalStreamsWOW =
            (this.props.trackStreamInfo && this.props.trackStreamInfo.delta) ||
            "N/A";
        const userTimeZone = guessTimeZone();

        const streamLineGraphProps: LineGraphProps | undefined = this.props
            .trackStreamInfo && {
            data: [
                parseTrackDataPoints(
                    this.props.trackStreamInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.totalStreamsTitle,
                },
                { "0": formatNumber(streamCount) }
            ),
            subtitle: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.weekOverWeek,
                },
                { "0": formatNumber(totalStreamsWOW) }
            ),
            labels:
                this.props.selectedRange === TimeRange.AllTime
                    ? [
                          getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.allTimeSubtitle,
                          }),
                      ]
                    : undefined,
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            id: testIDPrefix + "_StreamLineGraph",
            bundleMap: this.props.bundleMap,
        };

        const listenersCount =
            (this.props.trackListenerInfo &&
                this.props.trackListenerInfo.totalCount) ||
            0;
        const totalListenersWOW =
            (this.props.trackListenerInfo &&
                this.props.trackListenerInfo.delta) ||
            "N/A";

        const listenerLineGraphProps: LineGraphProps | undefined = this.props
            .trackListenerInfo && {
            data: [
                parseTrackDataPoints(
                    this.props.trackListenerInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.totalListenersTitle,
                },
                { "0": formatNumber(listenersCount) }
            ),
            subtitle: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.weekOverWeek,
                },
                { "0": formatNumber(totalListenersWOW) }
            ),
            labels:
                this.props.selectedRange === TimeRange.AllTime
                    ? [
                          getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.allTimeSubtitle,
                          }),
                      ]
                    : undefined,
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            id: testIDPrefix + "_ListenerLineGraph",
            bundleMap: this.props.bundleMap,
        };

        const streamSourcesChartProps: BarChartProps = {
            data: parseStreamSourceBar(
                this.props.streamSources,
                this.props.bundleMap
            ),
            title: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.sourceByStreamsTitle,
            }),
            id: testIDPrefix + "_StreamSourcesBarChart",
            paddingLeft: "15px",
            bundleMap: this.props.bundleMap,
        };

        const track = this.state.trackCatalogItem;
        const album = this.state.albumCatalogItem;

        const csvBasePayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: pagePath,
        };

        const trendlinesCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "trendlines",
            csvButtonId: CsvButtonId.SongDetailTrendlines,
            contentName: track?.title,
            streamsTrendlineData: this.props.trackStreamInfo,
            listenersTrendlineData: this.props.trackListenerInfo,
        };

        const playlistCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "featuredInPlaylists",
            csvButtonId: CsvButtonId.SongDetailFeaturedPlaylists,
            contentName: track?.title,
            playlistData: this.props.playlists,
        };

        const stationCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "featuredInStations",
            csvButtonId: CsvButtonId.SongDetailFeaturedStations,
            contentName: track?.title,
            stationData: this.props.stations,
        };

        let profileHeadProps: ProfileHeadProps = {
            imageSource: "",
            title: this.state.trackCatalogItem?.title || "",
            subtitle: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.songsLabel,
            }),
            id: testIDPrefix + testIDSuffixes.profileImage,
            showShareContentButton: true,
            shareContentAction: this.onShareContent,
            showCsvButton:
                this.props.hasCsvExportCapabilities &&
                (!!this.props.trackStreamInfo?.totalCount ||
                    !!this.props.trackListenerInfo?.totalCount),
            csvDownload: () => {
                this.props.sendClientMetrics(
                    buildUIClickPayload(
                        buttonIds.CsvExport.downloadTrendlines,
                        pageIds.songPerformance,
                        this.props.match.params.asin,
                        EntityType.Track
                    )
                );
                this.props.getCsvFile(trendlinesCsvPayload);
            },
            csvTooltipMessage: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.CSV_STRINGS,
                stringId: stringIds.Csv.TooltipMessage.StreamsAndListeners,
            }),
            csvButtonLoading:
                this.props.csvButtonLoadingStatus["SongDetail-Trendlines"],
        };

        if (track) {
            profileHeadProps = {
                ...profileHeadProps,
                title: track.title || "",
                imageSource:
                    track.images && track.images.artLarge
                        ? track.images.artLarge
                        : "",
                fallbackImage: ImageList.placeholder_track,
                imageDescription: track.title,
                callback: this.onReportIssue,
                callbackString: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                    stringId:
                        stringIds.ArtistDisambiguation
                            .reportArtistProfileIssueTitle,
                }),
            };
        }

        const newPlaylists = getNewPlaylistAsins(
            this.props.recentlyAddedToPlaylistData,
            track?.asin
        );

        const featuredPlaylists: TableRowProps[] = generatePlaylistRows(
            this.props.playlists,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            testIDPrefix + "_FeaturedInPlaylistsRows",
            this.props.bundleMap,
            newPlaylists
        );
        const featuredStations: TableRowProps[] = generateStationRows(
            this.props.stations,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            testIDPrefix + "_FeaturedStationsRows",
            this.props.bundleMap
        );

        // Top countries props
        const topCountriesHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.countriesTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const topCountries: TableRowProps[] = generateCountryRows(
            this.props.topCountries,
            testIDPrefix + "_TopCountriesRows"
        );

        const topCountriesRows = topCountries.slice(
            0,
            SUMMARY_TABLE_ITEM_COUNT
        );

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* <Image
                    style={rootStyles.imageStyles.backgroundImage}
                    blurRadius={rootStyles.blurAmount}
                    source={{ uri: profileHeadProps.imageSource || this.props.artist?.images?.artSmall }}
                /> */}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getSongPerformance}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {this.state.trackCatalogItem && (
                    <ProfileHead {...profileHeadProps} />
                )}

                <Row>
                    <Col xs={12} md={6}>
                        {/* song streams */}
                        {streamLineGraphProps && (
                            <LineGraph
                                key="songStreams"
                                {...streamLineGraphProps}
                            />
                        )}
                    </Col>
                    <Col xs={12} md={6}>
                        {/* song listeners */}
                        {listenerLineGraphProps && (
                            <LineGraph
                                key="songListeners"
                                {...listenerLineGraphProps}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {/* featured in playlists */}
                        <Table
                            key="featuredInPlaylists"
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SONGPERFORMANCE_STRINGS,
                                stringId:
                                    stringIds.SongPerformance
                                        .featuredInPlaylists,
                            })}
                            labels={[
                                getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.playlistsLabel,
                                }),
                                "",
                                getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.streamsLabel,
                                }),
                            ]}
                            rows={showTopNRows(
                                featuredPlaylists,
                                SUMMARY_TABLE_ITEM_COUNT
                            )}
                            showIndex={false}
                            id={testIDPrefix + "_FeaturedInPlaylistsTable"}
                            footer={
                                <Row style={{ alignItems: "center" }}>
                                    {this.props.hasCsvExportCapabilities &&
                                        featuredPlaylists.length && (
                                            <Col>
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoadingStatus[
                                                            "SongDetail-FeaturedPlaylists"
                                                        ]
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={
                                                        "CsvButton-SongPerformance-FeaturedPlaylists"
                                                    }
                                                    tooltipMessage={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CSV_STRINGS,
                                                            stringId:
                                                                stringIds.Csv
                                                                    .TooltipMessage
                                                                    .FeaturedInPlaylists,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        this.props.sendClientMetrics(
                                                            buildUIClickPayload(
                                                                buttonIds
                                                                    .CsvExport
                                                                    .downloadPlaylists,
                                                                pageIds.songPerformance,
                                                                this.props.match
                                                                    .params
                                                                    .asin,
                                                                EntityType.Track
                                                            )
                                                        );
                                                        this.props.getCsvFile(
                                                            playlistCsvPayload
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                    {featuredPlaylists.length >
                                        SUMMARY_TABLE_ITEM_COUNT && (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllPlayLists}
                                            id={
                                                testIDPrefix +
                                                "_SeeAllPlaylistsButton"
                                            }
                                        />
                                    )}
                                </Row>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* featured in stations */}
                        <Table
                            key="featuredInStations"
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SONGPERFORMANCE_STRINGS,
                                stringId:
                                    stringIds.SongPerformance
                                        .featuredInStations,
                            })}
                            labels={[
                                getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.stationsLabel,
                                }),
                                "",
                                getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.streamsLabel,
                                }),
                            ]}
                            rows={showTopNRows(
                                featuredStations,
                                SUMMARY_TABLE_ITEM_COUNT
                            )}
                            showIndex={false}
                            id={testIDPrefix + "_FeaturedInStationsTable"}
                            footer={
                                <Row style={{ alignItems: "center" }}>
                                    {this.props.hasCsvExportCapabilities &&
                                        featuredStations.length && (
                                            <Col>
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoadingStatus[
                                                            "SongDetail-FeaturedStations"
                                                        ]
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={
                                                        "CsvButton-SongPerformance-FeaturedStations"
                                                    }
                                                    tooltipMessage={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CSV_STRINGS,
                                                            stringId:
                                                                stringIds.Csv
                                                                    .TooltipMessage
                                                                    .FeaturedInStations,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        this.props.sendClientMetrics(
                                                            buildUIClickPayload(
                                                                buttonIds
                                                                    .CsvExport
                                                                    .downloadStations,
                                                                pageIds.songPerformance,
                                                                this.props.match
                                                                    .params
                                                                    .asin,
                                                                EntityType.Track
                                                            )
                                                        );
                                                        this.props.getCsvFile(
                                                            stationCsvPayload
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                    {featuredStations.length >
                                        SUMMARY_TABLE_ITEM_COUNT && (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllStations}
                                            id={
                                                testIDPrefix +
                                                "_SeeAllStationsButton"
                                            }
                                        />
                                    )}
                                </Row>
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            <VerticalSpacedTable
                                rows={topCountriesRows}
                                labels={topCountriesHeader}
                                showIndex={true}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports.topCountriesTitle,
                                    }
                                )}
                                id={testIDPrefix + "_TopCountriesTable"}
                                footer={
                                    <TableSeeAllButton
                                        totalRows={topCountries.length}
                                        id={testIDPrefix + "_seeAllCountries"}
                                        onClick={this.seeAllCountries}
                                    />
                                }
                                emptyMessage={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                        stringId: stringIds.EmptyState.message,
                                    }
                                )}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {streamSourcesChartProps && (
                            <BarChart
                                key="streamSources"
                                {...streamSourcesChartProps}
                            />
                        )}
                    </Col>
                </Row>
                <ShareContentMenu
                    fallbackImageSource={ImageList.placeholder_track}
                    isGenerateLinkInProgress={
                        this.props.generateShareLinkInProgress
                    }
                    isVisible={this.state.showShareContentMenu}
                    title={track?.title}
                    imageSource={track?.images?.artSmall}
                    contentType={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.songsLabel,
                    })}
                    artist={this.props.artist?.title}
                    artistAsin={this.props.artist?.asin}
                    albumAsin={album?.asin}
                    trackAsin={track?.asin}
                    albumName={album?.title}
                    id={`${testIDPrefix}_ShareContentMenu`}
                    shareContentResponse={this.props.shareLinkResponse}
                    onHide={() =>
                        this.setState({ showShareContentMenu: false })
                    }
                    sendClientMetrics={this.props.sendClientMetrics}
                    onCreatePromoCard={() => this.onCreatePromoCard()}
                    showHypeDeck={this.props.showHypeDeck}
                />
            </Container>
        );
    }

    private getSongPerformance = () => {
        const asin = this.props.match.params.asin;
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId ||
            !asin
        ) {
            return;
        }

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        // Dispatch request for overview
        const payload: songPerformanceRequestPayload = {
            timeRange: this.props.selectedRange,
            trackAsin: asin,
            teamId: this.props.teamId,
            query: {
                artistAsin: this.props.selectedArtist,
                trackStreamsQueryV2: {
                    ...generateQuery(timeRangePayload, guessTimeZone()),
                    trackAsin: asin,
                },
                trackListenersQueryV2: {
                    ...generateQuery(timeRangePayload, guessTimeZone()),
                    trackAsin: asin,
                },
                featuredPlaylistsQueryV2: {
                    ...generateQueryWithoutGranularity(
                        timeRangePayload,
                        guessTimeZone()
                    ),
                    trackAsin: asin,
                },
                featuredStationsQueryV2: {
                    ...generateQueryWithoutGranularity(
                        timeRangePayload,
                        guessTimeZone()
                    ),
                    trackAsin: asin,
                },
                streamSourcesQueryV2: {
                    ...generateQueryWithoutGranularity(
                        timeRangePayload,
                        guessTimeZone()
                    ),
                    trackAsin: asin,
                },
                topCountriesForEntityStreamsQuery: {
                    ...generateQueryWithoutGranularity(
                        timeRangePayload,
                        guessTimeZone()
                    ),
                    entityType: EntityType.Track,
                    entityId: asin,
                },
            },
            requestPath: pagePath,
        };

        this.props.getSongPerformance(payload);
    };

    private seeAllPlayLists = () => {

        this.props.history?.push(
            `${paths.playlists}/${this.state.trackCatalogItem?.asin}`
        );
    };

    private seeAllStations = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllStations,
                pageIds.songPerformance,
                this.state.trackCatalogItem?.asin,
                EntityType.Track
            )
        );

        this.props.history?.push(
            `${paths.stations}/${this.state.trackCatalogItem?.asin}`
        );
    };

    private seeAllCountries = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllCountries,
                pageIds.songPerformance,
                this.state.trackCatalogItem?.asin,
                EntityType.Track
            )
        );
        this.props.history.push(
            `${paths.songCountries}/${this.state.trackCatalogItem?.asin}`
        );
    };

    private onReportIssue = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.SettingMenu.reportIssue,
                pageIds.songPerformance,
                this.state.trackCatalogItem?.asin,
                EntityType.Track
            )
        );

        this.props.selectReportIssueItem(this.state.trackCatalogItem || {});
        this.props.history.push(paths.reportArtistProfile);
    };

    private onShareContent = () => {
        if (
            this.props.artist?.asin &&
            this.props.selectedAsin &&
            this.props?.teamId
        ) {
            this.setState({ showShareContentMenu: true });

            const payload: generateShareLinkPayload = {
                artistAsin: this.props.artist.asin,
                titleSetAsin: this.props.selectedAsin,
                teamId: this.props.teamId,
                contentType: EntityType.Track,
            };

            this.props.generateShareLink(payload);
        }
    };

    private onCreatePromoCard = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.HypeCard.createHypeCard,
                pageIds.songPerformance,
                this.props.match.params.asin,
                EntityType.Track
            )
        );
        const item: baseMediaItem = {
            title: this.state.trackCatalogItem?.title,
            images: this.state.trackCatalogItem?.images,
            type: EntityType.Track,
            titlesetAsin: this.props.selectedAsin,
        };
        this.props.history.push({
            pathname: paths.promoCard,
            state: {
                stepIndex: PromoCardStep.CUSTOMIZE,
                selectedContent: item,
            },
        });
    };
}

function showTopNRows(inputArray: any[], displayNumber: number) {
    const result = [];
    for (let i = 0; i < Math.min(inputArray.length, displayNumber); i++) {
        result.push(inputArray[i]);
    }
    return result;
}

function mapStateToProps(state: RootState) {
    return songsPerformanceScreenSelector(state, pagePath);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getSongPerformance: (request: songPerformanceRequestPayload) =>
            dispatch(reportingActions.getSongPerformance(request)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        selectAsin: (asin: string) =>
            dispatch(reportingActions.setSelectedSongAsin(asin)),
        unselectAsin: (asin: string) =>
            dispatch(reportingActions.removeSelectedSongAsin(asin)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        selectReportIssueItem: (payload: baseMediaItem) =>
            dispatch(artistIssueActions.selectReportIssueItem(payload)),
        generateShareLink: (payload: generateShareLinkPayload) =>
            dispatch(catalogActions.generateShareLink(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SongPerformanceScreen)
);
