import * as React from "react";
import { Modal, Row, Spinner } from "react-bootstrap";
import { RootState } from "../../../store";
import { connect } from "react-redux";
import * as rootStyles from "../../styles";
import styled from "styled-components";
import { BlurredModal, Icon, IconsList, ImageWithFallback } from "../common";
import { MediumGlassButton } from "./input";
import { buildUIClickPayload, getLocalizedString } from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    BundleMap,
    clientMetricsPayload,
    EntityType,
    shareContentResponse
} from "../../../models";
import { styledTitle } from "./Title";
import { InlineError } from "./error";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { BaseShareModal } from "./BaseShareModal";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

const metricPrefix = "ShareContentMenu";

export type ShareContentMenuProps = {
    isVisible: boolean;
    isGenerateLinkInProgress: boolean;
    id: string;
    contentType: string;
    title?: string;
    albumName?: string;
    artist?: string;
    artistAsin?: string;
    albumAsin?: string;
    trackAsin?: string;
    imageSource?: string;
    fallbackImageSource: string;
    shareContentResponse?: shareContentResponse;
    onHide: () => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    showHypeDeck?: boolean;
    onCreatePromoCard?: () => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = StateProps & ShareContentMenuProps;

export type State = {
    isCopyLinkSelected: boolean;
    showEmbedLink: boolean;
};

class ShareContentMenuClass extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isCopyLinkSelected: false,
            showEmbedLink: false,
        };
    }

    render() {
        const messageText = getLocalizedString(
            this.props.bundleMap,
            {
                bundleId: bundleIds.CONTENTSHARING_STRINGS,
                stringId: stringIds.ContentSharing.checkThisOutText,
            },
            { "0": this.props.title || "" }
        );

        const showError =
            !this.props.shareContentResponse ||
            !this.props.shareContentResponse.generateSuccessful;

        return (
            <BaseShareModal
                isVisible={this.props.isVisible}
                onHide={this.props.onHide}
                loadingInProgress={this.props.isGenerateLinkInProgress}
                modalTitle={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.CONTENTSHARING_STRINGS,
                    stringId: stringIds.ContentSharing.shareModalTitle,
                })}
                modalSubtitle={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.CONTENTSHARING_STRINGS,
                    stringId: stringIds.ContentSharing.shareModalSubtitle,
                })}
                id={this.props.id}
                contentType={this.props.contentType}
                contentTitle={this.props.title || ""}
                contentSecondary={this.props.artist}
                contentTertiary={this.props.albumName}
                contentImage={this.props.imageSource}
                fallbackImageSource={this.props.fallbackImageSource}
                shareLink={this.props.shareContentResponse?.link}
                embedTag={this.props.shareContentResponse?.embedTag}
                messageText={messageText}
                showError={showError}
                sendShareClickMetric={this.sendShareClickMetric}
                bundleMap={this.props.bundleMap}
                onCreatePromoCard={this.props.onCreatePromoCard}
                showHypeDeck={this.props.showHypeDeck}
            />
        );
    }

    private sendShareClickMetric = (buttonName: string) => {
        // Check sharing from albumPerformance or songPerformance screen
        if (this.props.trackAsin) {
            this.props.sendClientMetrics(
                buildUIClickPayload(
                    buttonName,
                    pageIds.songPerformance,
                    this.props.trackAsin,
                    EntityType.Track
                )
            );
        } else if (this.props.albumAsin) {
            this.props.sendClientMetrics(
                buildUIClickPayload(
                    buttonName,
                    pageIds.albumPerformance,
                    this.props.albumAsin,
                    EntityType.Album
                )
            );
        }
    };
}

const ModalContainer = styled.div`
    .modal-dialog-centered {
        @media (max-width: ${rootStyles.breakpoints.lg}px) {
            justify-content: center;
        }
    }
`;

const IconContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
    width: 36,
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: rootStyles.glassColors.primary2,
};

const IconContainerWithMarginStyle: React.CSSProperties = {
    ...IconContainerStyle,
    marginRight: rootStyles.spacers.mini,
};

const subtitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    textAlign: "left",
    color: rootStyles.colors.accent,
};

const shareLinkStyle: React.CSSProperties = {
    padding: 0,
    outline: 0,
    textAlign: "left",
    width: "100%",
    margin: 0,
    ...rootStyles.textStyles.primary,
    color: rootStyles.colors.primary,
    backgroundColor: "transparent",
    resize: "none",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
};

const hrefTarget = "_blank";

const hrefRel = "noopener noreferrer";

const twitterHashtag = "AmazonMusic";

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const ShareContentMenu = connect(mapStateToProps)(ShareContentMenuClass);
