export type metricDataPoints = Map<string, string | undefined>;

export enum MetricType {
    AppEvent = "AppEvent",
    UserAction = "UserAction",
    Error = "Error",
}

export const METRIC_KEYS = {
    loadTime: "LoadTime",
    timeStamp: "Timestamp",
    errorCode: "ErrorCode",
    errorName: "ErrorName",
    errorMessage: "ErrorMessage",
    errorAction: "ErrorAction",
    artistAsin: "ArtistAsin",
    userId: "UserId",
    page: "Page",
    type: "Type",
    count: "Count",
    trackAsin: "TrackAsin",
    albumAsin: "AlbumAsin",
    stationId: "StationId",
    playlistId: "PlaylistId",
    teamId: "TeamId",
    timeRange: "TimeRange",
    listIndex: "ListIndex",
    locale: "Locale",
    asin: "Asin",
    file: "File",
    userAgent: "UserAgent",
    browserName: "BrowserName",
    browserVersion: "BrowserVersion",
    deviceType: "DeviceType",
    osName: "OSName",
    osVersion: "OSVersion",
    mobileVendor: "MobileVendor",
    mobileModel: "MobileModel",
    engineName: "EngineName",
    engineVersion: "EngineVersion",
    durationPlayed: "DurationPlayed",
    stringId: "StringId",
    stringBundle: "stringBundle",
    deeplink: "Deeplink",
    invalidArtistAsin: "DeeplinkInvalidArtistAsin",
    undefinedArtistAsin: "DeeplinkUndefinedArtistAsin",
    invalidAction: "DeeplinkInvalidAction",
    undefinedAction: "DeeplinkUndefinedAction",
    undefinedHistoryProps: "undefinedHistoryProps",
};
