import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AnyAction, Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { stringIds, ImageList, IconsList, bundleIds } from "../../../assets";
import * as rootStyles from "../../styles";
import {
    artist,
    teamInfo,
    BundleMap,
} from "../../../models";
import {
    getLocalizedString,
    testIDSuffixes,
    paths,
    LEARN_MORE_ABOUT_CLAIMS_URL,
} from "../../../utils";
import {
    LargeOutlineButton,
    ImageWithFallback,
    Icon,
    styledTitle,
    LargeSolidButton,
    ClaimHeader,
} from "../../components";
import {
    RootState,
    oAuthActions,
    userActions,
} from "../../../store";
import { connect } from "react-redux";
import { getBundleMap } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "ClaimSubmissionOutScreen";
const metricPrefix = "claimSubmissionOutPage";

type ViewProps = {};

type StateProps = {
    selectedArtist?: artist;
    teams?: teamInfo[];
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    setHideIntercomLauncher: (payload: boolean) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps<ViewProps>;

type State = {};

class ClaimSubmissionOutScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        if (!this.props.selectedArtist) {
            this.props.history.push(paths.artistSearch);
        }
        this.props.setHideIntercomLauncher(false);
    }

    componentWillUnmount() {
        this.props.setHideIntercomLauncher(true);
    }

    render() {
        const artistName = this.props.selectedArtist?.title;
        const artistImage = this.props.selectedArtist?.imageUrl;

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ClaimHeader />
                <div style={containerStyle}>
                    {/* <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: artist?.images?.artLarge }}
                    /> */}
                    <Row style={centerAlign}>
                        <ImageWithFallback
                            style={rootStyles.imageStyles.roundImage}
                            source={artistImage || ""}
                            id={testIDPrefix + testIDSuffixes.profileImage}
                            fallback={ImageList.placeholder_artist}
                        />
                    </Row>
                    <Row style={centerAlign}>
                        <span style={rootStyles.textStyles.label}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                                stringId: stringIds.ClaimThisArtist.artist,
                            })}
                        </span>
                    </Row>
                    <Row style={centerAlign}>
                        <styledTitle.h2
                            style={artistNameStyle}
                            id={`${testIDPrefix}_ArtistName`}
                        >
                            {artistName}
                        </styledTitle.h2>
                    </Row>
                    <Row
                        style={{
                            ...centerAlign,
                            marginBottom: rootStyles.spacers.large,
                        }}
                    >
                        <Row>
                            <Col style={{ width: "100%" }}>
                                <Icon
                                    size={24}
                                    icon={IconsList.ic_time_played}
                                    id={`${testIDPrefix}_PendingIcon`}
                                />
                                <styledTitle.h4
                                    style={{
                                        marginLeft: 5,
                                        verticalAlign: "middle",
                                    }}
                                    id={`${testIDPrefix}_TeamPendingTitleInfoText`}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.CLAIMTHISARTIST_STRINGS,
                                        stringId:
                                            stringIds.ClaimThisArtist
                                                .claimPendingTitle,
                                    })}
                                </styledTitle.h4>
                            </Col>
                        </Row>
                        <Row style={centerAlign}>
                            <Col
                                style={{
                                    ...centerAlign,
                                    textAlign: "center",
                                    maxWidth: 400,
                                }}
                            >
                                <span
                                    style={rootStyles.textStyles.primary}
                                    id={`${testIDPrefix}_TeamPendingLabelInfoText`}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.CLAIMTHISARTIST_STRINGS,
                                        stringId:
                                            stringIds.ClaimThisArtist
                                                .claimPendingLabel,
                                    })}
                                </span>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                ...centerAlign,
                                marginTop: rootStyles.spacers.large,
                                textAlign: "center",
                                width: "100%",
                                maxWidth: 400,
                            }}
                        >
                            <LargeSolidButton
                                containerStyle={buttonStyle}
                                onClick={() =>
                                    window.open(
                                        LEARN_MORE_ABOUT_CLAIMS_URL,
                                        "_blank"
                                    )
                                }
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.CLAIMTHISARTIST_STRINGS,
                                        stringId:
                                            stringIds.ClaimThisArtist.learnMore,
                                    }
                                )}
                                id={`${testIDPrefix}_learnMore`}
                            />
                        </Row>
                        {this.props.teams && this.props.teams.length > 0 ? (
                            <Row
                                style={{
                                    ...centerAlign,
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: 400,
                                }}
                            >
                                <LargeOutlineButton
                                    containerStyle={buttonStyle}
                                    onClick={() => this.claimAnother()}
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.CLAIMTHISARTIST_STRINGS,
                                            stringId:
                                                stringIds.ClaimThisArtist
                                                    .claimAnotherArtist,
                                        }
                                    )}
                                    id={`${testIDPrefix}_claimAnotherArtist`}
                                />
                            </Row>
                        ) : null}
                    </Row>
                </div>
            </Container>
        );
    }

    private claimAnother = () => {
        this.props.history?.replace(paths.artistSearch);
    };
}

function mapStateToProps(state: RootState): StateProps {
    return {
        selectedArtist: state.artistSearch.selectedArtist,
        teams: state.user.teams ? state.user.teams : [],
        bundleMap: getBundleMap(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        setHideIntercomLauncher: (payload: boolean) =>
            dispatch(oAuthActions.setHideIntercomLauncher(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
    };
}

const containerStyle: React.CSSProperties = {
    flex: 1,
    paddingLeft: rootStyles.spacers.large,
    paddingRight: rootStyles.spacers.large,
    marginTop: rootStyles.spacers.large,
    flexDirection: "column",
};
const artistNameStyle: React.CSSProperties = {
    textAlign: "center",
    marginBottom: rootStyles.spacers.large,
};
const buttonStyle: React.CSSProperties = {
    width: "80%",
    padding: rootStyles.spacers.mini,
    marginTop: rootStyles.spacers.medium,
    alignSelf: "center",
};
const centerAlign: React.CSSProperties = {
    flexDirection: "column",
    alignItems: "center",
};
const infoText: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    marginTop: rootStyles.spacers.large,
    textAlign: "center",
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ClaimSubmissionOutScreen)
);
