import { put, takeEvery } from "redux-saga/effects";
import { Action } from "redux-ts";
import {
    GetFeaturePermissionsPayload,
    GetFeaturePermissionsResponse,
    GetGlobalPermissionsPayload,
    GetGlobalPermissionsResponse,
} from "../../models";
import * as service from "../../service";
import { createSuccessOpsMetricsPayload } from "../../utils";
import {
    errorActions,
    featureAccessActions,
    opsMetricsActions,
} from "../actions";

export const featureAccessSagas = [
    watchGetFeaturePermissions(),
    watchGetGlobalPermissions(),
];

function* getFeaturePermissions(action: Action<GetFeaturePermissionsPayload>) {
    const start = Date.now();
    const functionName = "getFeaturePermissions";
    try {
        yield put(featureAccessActions.isFeaturePermissionInProgress(true));

        const response: GetFeaturePermissionsResponse =
            yield service.getFeaturePermissions({
                teamId: action.payload.teamId,
                artistAsin: action.payload.artistAsin,
                upToDateFeatures: action.payload.upToDateFeatures,
            });

        const featurePermissionsList = response.featurePermissions;
        yield put(
            featureAccessActions.getFeaturePermissionsSuccess(
                featurePermissionsList
            )
        );
        yield put(featureAccessActions.isFeaturePermissionInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(featureAccessActions.isFeaturePermissionInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                silent: true,
                shouldRetry: true,
                dontRefreshOutageStatus: true,
            })
        );
    }
}

function* watchGetFeaturePermissions() {
    yield takeEvery(
        featureAccessActions.getFeaturePermissions.type,
        getFeaturePermissions
    );
}

function* getGlobalPermissions(action: Action<GetGlobalPermissionsPayload>) {
    const start = Date.now();
    const functionName = "getGlobalPermissions";
    try {
        const response: GetGlobalPermissionsResponse =
            yield service.getGlobalPermissions({
                upToDateFeatures: action.payload.upToDateFeatures,
            });

        yield put(
            featureAccessActions.getGlobalPermissionsSuccess(
                response.featurePermissions
            )
        );
        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                silent: true,
                shouldRetry: true,
                dontRefreshOutageStatus: true,
            })
        );
    }
}

function* watchGetGlobalPermissions() {
    yield takeEvery(
        featureAccessActions.getGlobalPermissions.type,
        getGlobalPermissions
    );
}
