import { channel } from "redux-saga";
import { Action } from "redux-ts";
import { put, select, take, takeEvery } from "redux-saga/effects";
import {
    createSuccessOpsMetricsPayload,
    getAllTimeStartDate,
    getStartDate,
} from "./../../../utils";
import {
    createCsvFileRequestPayload,
    METRIC_KEYS,
    TimeRange,
    CatalogItemType,
    BundleMap,
} from "../../../models/";
import {
    csvActions,
    errorActions,
    opsMetricsActions,
    reportingActions,
} from "../../actions";
import {
    createTrendlinesCsvFile,
    createTopContentCsvFile,
    createPlaylistsCsvFile,
    createFansCsvFile,
} from "./";
import { RootState, CatalogState } from "./../../reducers";
import { AnyAction } from "redux";
import { getBundleMap } from "../../selectors/commonSelectors";

export const csvSagas = [watchcreateCsvFile(), watchEmitCsvMetrics()];

const emitCsvMetricsChannel = channel();

function* createCsvFile(action: Action<createCsvFileRequestPayload>) {
    const start = Date.now();
    const csvExportType = action.payload.csvExportType;
    const functionName = `csvSaga_${csvExportType}`;
    const endDate: Date = action.payload.endDate || new Date();
    const startDate: Date =
        action.payload.startDate ||
        (action.payload.timeRange === TimeRange.AllTime
            ? getAllTimeStartDate()
            : (() => {
                  const startDate = getStartDate(action.payload.timeRange);
                  if (!startDate) {
                      throw new Error(
                          `Start date is undefined for time range ${action.payload.timeRange}`
                      );
                  }
                  return startDate;
              })());

    const catalogState: CatalogState = yield select(
        (state: RootState) => state.catalog
    );
    const bundleMap: BundleMap = yield select(getBundleMap);

    const basePayload = {
        startDate: startDate,
        endDate: endDate,
        requestPath: action.payload.requestPath,
        artistName: action.payload.artistName,
    };
    const hydrationPayload =
        action.payload.teamId && action.payload.locale
            ? {
                  catalogState: catalogState,
                  teamId: action.payload.teamId as string,
                  locale: action.payload.locale as string,
                  contentName: action.payload.contentName,
              }
            : undefined;

    const metricPayload = new Map<string, string | undefined>([
        [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        [METRIC_KEYS.teamId, `${action.payload.teamId}`],
        [METRIC_KEYS.timeRange, `${action.payload.timeRange}`],
        [METRIC_KEYS.type, `${csvExportType}`],
        [METRIC_KEYS.page, action.payload.requestPath],
    ]);

    try {
        yield put(
            reportingActions.csvDownloadInProgress(action.payload.csvButtonId)
        );


        let csvMissingData = false;
        switch (csvExportType) {
            case "trendlines": {
                if (
                    !action.payload.streamsTrendlineData ||
                    !action.payload.listenersTrendlineData
                ) {
                    csvMissingData = true;
                    break;
                }
                createTrendlinesCsvFile({
                    ...basePayload,
                    streamsTrendlineData: action.payload.streamsTrendlineData,
                    listenersTrendlineData:
                        action.payload.listenersTrendlineData,
                    contentName: action.payload.contentName,
                    bundleMap: bundleMap,
                });
                break;
            }
            case "topSongs":
            case "topSongsOnAlbum": {
                if (!action.payload.trackData || !hydrationPayload) {
                    csvMissingData = true;
                    break;
                }

                yield createTopContentCsvFile({
                    ...basePayload,
                    ...hydrationPayload,
                    csvType: csvExportType,
                    catalogItemType: CatalogItemType.Tracks,
                    contentInfoList: action.payload.trackData.map((track) => {
                        return {
                            asin: track.asin,
                            streams: track.streams,
                        };
                    }),
                    bundleMap: bundleMap,
                });
                break;
            }
            case "topAlbums": {
                if (!action.payload.albumData || !hydrationPayload) {
                    csvMissingData = true;
                    break;
                }
                yield createTopContentCsvFile({
                    ...basePayload,
                    ...hydrationPayload,
                    csvType: csvExportType,
                    catalogItemType: CatalogItemType.Albums,
                    contentInfoList: action.payload.albumData.map((album) => {
                        return {
                            asin: album.asin,
                            streams: album.streams,
                        };
                    }),
                    bundleMap: bundleMap,
                });
                break;
            }
            case "featuredInPlaylists": {
                if (!action.payload.playlistData || !hydrationPayload) {
                    csvMissingData = true;
                    break;
                }
                yield createPlaylistsCsvFile({
                    ...basePayload,
                    ...hydrationPayload,
                    playlistData: action.payload.playlistData,
                    bundleMap: bundleMap,
                });
                break;
            }
            case "featuredInStations": {
                if (!action.payload.stationData || !hydrationPayload) {
                    csvMissingData = true;
                    break;
                }
                yield createTopContentCsvFile({
                    ...basePayload,
                    ...hydrationPayload,
                    csvType: csvExportType,
                    catalogItemType: CatalogItemType.Stations,
                    contentInfoList: action.payload.stationData.map(
                        (station) => {
                            return {
                                asin: station.id,
                                streams: station.streams,
                            };
                        }
                    ),
                    bundleMap: bundleMap,
                });
                break;
            }
            case "fans": {
                if (!action.payload.fanData) {
                    csvMissingData = true;
                    break;
                }
                createFansCsvFile({
                    ...basePayload,
                    fanData: action.payload.fanData,
                    bundleMap: bundleMap,
                });
                break;
            }
        }
        yield put(
            reportingActions.csvDownloadCompleted(action.payload.csvButtonId)
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: metricPayload,
            })
        );

        yield put(
            reportingActions.csvDownloadCompleted(action.payload.csvButtonId)
        );
    }
}

function* watchEmitCsvMetrics() {
    while (true) {
        const action: AnyAction = yield take(emitCsvMetricsChannel);
        yield put(action);
    }
}

function* watchcreateCsvFile() {
    yield takeEvery(csvActions.createCsvFile.type, createCsvFile);
}
